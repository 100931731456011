
























import Vue from "vue";
import { Store } from "vuex";
import { RootState } from "@/store";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import SideBar from "@/components/SideBar.vue";
import Organizations from "./views/Organizations.vue";

export default Vue.extend({
  components: { FullPageLayout, SideBar, Organizations },
  data: function () {
    return {
      height: 800,
      width: 700,
      userHasPermission: false,
      dashboardItems: {
        title: "Manage",
        class: "bg-manage",
        url: "",
        isOpen: false,
        children: [
          {
            title: "Design",
            class: "bg-design",
            url: "/design/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isDesigner;
            },
          },
          {
            title: "Build",
            class: "bg-build",
            url: "/build/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isBuilder;
            },
          },
        ],
      },
      menuItems: [
        {
          title: "Orders",
          url: "/manage/orders/",
        },
        {
          title: "Organizations",
          url: "/manage/organizations/",
        },
      ],
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.fitStageIntoParentContainer);
    this.fitStageIntoParentContainer();
    if (document.getElementById("dashboard-title")) {
      (document.getElementById("dashboard-title") as HTMLElement).textContent =
        "Manage Dashboard";
    }
    if (document.getElementById("sa-logo-link")) {
      (document.getElementById("sa-logo-link") as HTMLAnchorElement).href =
        "/manage/";
    }
  },
  methods: {
    fitStageIntoParentContainer: function () {
      this.$store.commit("screenResized");
      this.$store.commit("setClientHeight", window.innerHeight - 55);
      this.width = window.innerWidth - 320;
    },
  },
});
