
















































import Vue from "vue";
import { PropType } from "vue";
import $ from "jquery";

import { IDashboardState } from "../types";
import ProgressBar from "./ProgressBar.vue";

export default Vue.extend({
  components: { ProgressBar },
  props: {
    states: {
      type: Array as PropType<IDashboardState[]>,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  mounted: function () {
    $('[data-toggle="popover"]').popover();
  },
  methods: {
    /*
     * returns true if at least one user exists in the assigned group
     */
    hasReviewer: function (state: IDashboardState): boolean {
      return state.companies.some((company) => {
        return company.groups.some((group) => {
          return group.name === "assigned" && group.users.length > 0;
        });
      });
    },
    approvedSignCount: function (state: IDashboardState): number {
      if (state.acceptedSigns) {
        return state.acceptedSigns;
      } else {
        return 0;
      }
    },
    rejectedSignCount: function (state: IDashboardState): number {
      if (state.rejectedSigns) {
        return state.rejectedSigns;
      } else {
        return 0;
      }
    },
    reviewSignCount: function (state: IDashboardState): number {
      if (state.needsReviewedSigns) {
        return state.needsReviewedSigns;
      } else {
        return 0;
      }
    },
    totalPercentage: function (state: IDashboardState): number {
      const reviewCount = this.reviewSignCount(state);
      const approvedCount = this.approvedSignCount(state);
      const rejectedCount = this.rejectedSignCount(state);
      const total = reviewCount + approvedCount + rejectedCount;
      let rejectedPercent = 0;
      let approvedPercent = 0;
      if (total !== 0) {
        approvedPercent = (100 * approvedCount) / total;
        rejectedPercent = (100 * rejectedCount) / total + approvedPercent;
      }
      return rejectedPercent;
    },
  },
  computed: {},
});
