


























import Vue from "vue";
import { PropType } from "vue";
import { IDashboardState } from "../types";

export default Vue.extend({
  components: {},
  props: {
    states: {
      type: Array as PropType<IDashboardState[]>,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    totalSigns: function (): number | null {
      let result = 0;
      let foundQuantity = false;

      this.states.forEach((state) => {
        if (state.signQuantity) {
          foundQuantity = true;
          result += state.signQuantity;
        }
      });
      return foundQuantity ? result : null;
    },
  },
});
