

























































































































import Vue from "vue";
import { Store } from "vuex";
import { BootstrapVue } from "bootstrap-vue";
import { RootState } from "@/store";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import SideBar from "@/components/SideBar.vue";
import { IMenuItem } from "@/features/types";

export default Vue.extend({
  components: { FullPageLayout, SideBar },
  data: function () {
    return {
      pageHeight: 0,
      pageWidth: 0,
      userHasPermission: true,
      inGoodStanding: true,
      dashboardItems: {
        title: "Company",
        class: "bg-main",
        url: "",
        isOpen: false,
        children: [
          {
            title: "Design",
            class: "bg-design",
            url: "/design/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isDesigner;
            },
          },
          {
            title: "Build",
            class: "bg-build",
            url: "/build/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isBuilder;
            },
          },
          {
            title: "Manage",
            class: "bg-manage",
            url: "/manage/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isSuperUser;
            },
          },
        ],
      },
      menuItems: [] as Array<IMenuItem>,
    };
  },
  mounted: async function () {
    this.fitModalIntoParentContainer();
    window.addEventListener("resize", this.fitModalIntoParentContainer);

    this.checkPermissionToCompany().then(() => {
      if (this.userHasPermission) {
        this.getBillingData();
      }
    });

    if (window.Stripe) {
      if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
        this.$store.commit(
          "setStripe",
          window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
        );
      }
    }

    const dbTitle = document.getElementById("dashboard-title");
    if (dbTitle) {
      dbTitle.textContent = "Companies";
      dbTitle.style.color = "var(--main-color)";
    }

    const saLogo = document.getElementById("sa-logo-link") as HTMLAnchorElement;
    if (saLogo) {
      saLogo.href = "/company/" + this.$route.params.id;
    }
  },
  created: function () {
    this.menuItems = [
      {
        title: "Company",
        url: "/company/" + this.$route.params.id,
        showItem: true,
      },
      {
        title: "Billing",
        url: "/company/" + this.$route.params.id + "/billing/",
        showItem: true,
      },
      {
        title: "Members",
        url: "/company/" + this.$route.params.id + "/members/",
        showItem: true,
      },
      {
        title: "Update License",
        showItem: !this.inGoodStanding,
        url: "/company/" + this.$route.params.id + "/paywall/",
      },
      {
        title: "-",
        showItem: this.$store.getters.sessionCompany.isSalesCompany,
      },
      {
        title: "Companies",
        showItem: this.$store.getters.sessionCompany.isSalesCompany,
        url: "/company/" + this.$store.getters.sessionCompany.id + "/companies",
      },
      {
        title: "Price Plans",
        showItem: this.$store.getters.sessionCompany.isSalesCompany,
        url:
          "/company/" +
          this.$store.getters.sessionCompany.id +
          "/priceplans/list",
      },
    ] as Array<IMenuItem>;
  },
  methods: {
    checkPermissionToCompany: async function () {
      const query = JSON.stringify({
        query: `query {
          company (id: ${this.$route.params.id}) {
            name
          }
        }`,
      });

      await fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            if (result.errors[0].code === 403) {
              this.userHasPermission = false;
            } else {
              this.userHasPermission = true;
            }
          } else {
            this.userHasPermission = true;
          }
        });
    },

    getBillingData: async function () {
      // get user info so we can determine if they are in good standing
      const query2 = JSON.stringify({
        query: `query get_user_info{
                  user(id: ${this.$store.state.session.userId}) {
                    inGoodStanding
                  }
                }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query2,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((result) => result.json())
        .then((data) => {
          this.inGoodStanding = data.data.user.inGoodStanding;
        });
    },
    fitModalIntoParentContainer: function () {
      this.pageHeight = window.innerHeight - 50;
      this.pageWidth = window.innerWidth - 193; // 178 for sidebar and 15 for margin
    },
  },
  computed: {
    goodStanding(): boolean {
      return this.inGoodStanding;
    },
    isSalesCompanyUser(): boolean {
      if (
        this.$store.getters.sessionCompany &&
        this.$store.getters.sessionCompany.isSalesCompany
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
});
