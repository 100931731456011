
























import Vue from "vue";
import { Store } from "vuex";
import { RootState } from "@/store";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import SideBar from "@/components/SideBar.vue";
import DesignProjects from "./views/DesignProjects.vue";
import WelcomeModal from "@/features/WelcomeModal.vue";
import mountAsModal from "@/mounters/mountAsModal";

export default Vue.extend({
  components: { FullPageLayout, SideBar, DesignProjects },
  data: function () {
    return {
      height: 800,
      width: 700,
      userHasPermission: false,
      dashboardItems: {
        title: "Design",
        class: "bg-design",
        url: "",
        isOpen: false,
        children: [
          {
            title: "Build",
            class: "bg-build",
            url: "/build/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isBuilder;
            },
          },
          {
            title: "Manage",
            class: "bg-manage",
            url: "/manage/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isSuperUser;
            },
          },
        ],
      },
      menuItems: [
        {
          title: "+ New Organization",
          url: "/organization/create/",
          tooltip: "This will create a new (empty) organization.",
        },
        {
          title: "+ Starter Organization",
          url: "/organization/copy/",
          tooltip:
            "This will create a starter organization - complete with locations, sign types, and signs - to help you get a feel for the site.",
        },
        {
          title: "+ Clone Organization",
          url: "/organization/copy/",
          tooltip:
            "This will copy an existing organization you have admin permission for - complete with locations, sign types, and signs.",
        },
      ],
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.fitStageIntoParentContainer);
    this.fitStageIntoParentContainer();
    if (document.getElementById("dashboard-title")) {
      (document.getElementById("dashboard-title") as HTMLElement).textContent =
        "Design Dashboard";
    }
    if (document.getElementById("sa-logo-link")) {
      (document.getElementById("sa-logo-link") as HTMLAnchorElement).href =
        "/design/";
    }
    if ("welcome" in this.$route.query) {
      const showWelcomeModal = mountAsModal(WelcomeModal);
      showWelcomeModal({
        firstName: this.$store.state.session.firstName ?? "",
      });
    }
  },
  methods: {
    fitStageIntoParentContainer: function () {
      this.$store.commit("screenResized");
      this.$store.commit("setClientHeight", window.innerHeight - 55);
      this.width = window.innerWidth - 320;
    },
  },
});
