










































































































































import Vue from "vue";
import { PropType } from "vue";
import $ from "jquery";

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import { IMenuItem } from "@/features/types";

interface IDashboardItem {
  title: string;
  class: string;
  url: string;
  isOpen: boolean;
  children: [
    {
      title: string;
      class: string;
      url: string;
    }
  ];
}

export default Vue.extend({
  components: { Splitpanes, Pane },
  props: {
    dashboardItems: {
      type: Object as PropType<IDashboardItem>,
      required: true,
    },
    menuItems: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
    minWidthPercent: {
      type: Number,
      default: 16.66,
    },
    maxWidthPercent: {
      type: Number,
      default: 30,
    },
    startWidthPercent: {
      type: Number,
      default: 16.66,
    },
    theme: {
      type: String,
      default: "build",
      validator: function (value: string) {
        // The value must be one of these specific strings
        return ["design", "build", "manage", "main"].includes(value);
      },
    },
  },
  data: function () {
    return {
      sidebarWidth: this.startWidthPercent,
      oldWidth: this.startWidthPercent,
      sidebarStartWidth: this.startWidthPercent + "%",
      sidebarMinWidth: this.minWidthPercent + "%",
      sidebarMaxWidth: this.maxWidthPercent + "%",
      showPopupHelp: false,
      sidebarSlidOpen: false,
      isLeftMouseButtonDown: false,
      showDashboardLinks: false,
    };
  },
  mounted: function () {
    document.addEventListener("sidebarToggled", this.toggleSidebar);
    document.addEventListener("mousemove", this.onMouseMoved);

    document.addEventListener("mousedown", (event) => {
      if (event.button === 0) {
        this.isLeftMouseButtonDown = true;
      }
    });

    document.addEventListener("mouseup", (event) => {
      if (event.button === 0) {
        this.isLeftMouseButtonDown = false;
      }
    });

    const element = document.getElementById("navbar-header");
    if (element) {
      element.style.width = this.sidebarStartWidth;
      element.style.minWidth = this.sidebarMinWidth;
      element.style.maxWidth = this.sidebarMaxWidth;
    }

    const splitterGrabber = document.querySelector(
      ".splitpanes__splitter"
    ) as HTMLDivElement;

    if (splitterGrabber) {
      splitterGrabber.addEventListener("mouseover", (event: MouseEvent) => {
        this.showPopupHelp = true;
        // set the position of the popup help
        const windowWidth = window.innerWidth;
        let elementWidthInPixels = (this.sidebarWidth / 100) * windowWidth;
        elementWidthInPixels += 15; // add some spacing
        // set left position
        (this.$refs.popupHelp as HTMLElement).style.left =
          elementWidthInPixels + "px";
        // set top position
        (this.$refs.popupHelp as HTMLElement).style.top =
          event.clientY - 50 - 25 + "px";
      });

      splitterGrabber.addEventListener("mouseout", () => {
        this.showPopupHelp = false;
      });
    }

    // set the background color of the navbar header
    const navbar = document.getElementById("navbar-header");
    if (navbar) {
      const classes = Array.from(navbar.classList);

      // Filter and remove classes that start with "bg-"
      classes.forEach((className) => {
        if (className.startsWith("bg-")) {
          navbar.classList.remove(className);
        }
      });

      navbar.classList.add("bg-" + this.theme);
    }
  },
  methods: {
    toggleSidebar: function () {
      if (
        this.sidebarSlidOpen ||
        document.body.classList.contains("sidebar--opened")
      ) {
        this.lockSidebar();
      } else {
        this.collapseSidebar();
      }
    },
    lockSidebar: function () {
      if (this.sidebarSlidOpen) {
        const showSidebar = true;
        this.sidebarSlidOpen = false;

        $("body")
          .toggleClass("sidebar--opened", showSidebar)
          .toggleClass("sidebar--closed", !showSidebar);
      } else {
        this.sidebarSlidOpen = false;
        this.sidebarWidth = this.oldWidth;
      }

      const element = document.getElementById("navbar-header");
      if (element) {
        element.style.width = this.sidebarWidth + "%";
      }
    },
    onMouseMoved: function (event: MouseEvent) {
      // is the side bar slid open
      if (this.sidebarSlidOpen) {
        if (!this.isLeftMouseButtonDown) {
          // get the width of the menu pane with a 10 pixel padding
          const menuPane = document.getElementById("menuPane");
          let menuWidth = 0;
          if (menuPane) {
            menuWidth = menuPane.clientWidth + 10;
          }
          // if the mouse's x position is beyond the width of the menu
          if (event.clientX > menuWidth) {
            // close the menu
            this.sidebarWidth = 0;
            this.sidebarSlidOpen = false;
            const showSidebar = false;
            $("body")
              .toggleClass("sidebar--opened", showSidebar)
              .toggleClass("sidebar--closed", !showSidebar);

            const element = document.getElementById("navbar-header");
            if (element) {
              element.style.width = "0%";
            }
          }
        }
      } else {
        // the sidebar isn't open
        // if the mouse is within 10 pixels of the left side of the screen
        if (document.body.classList.contains("sidebar--closed")) {
          if (event.clientX < 10) {
            // slide the menubar open
            this.sidebarWidth = this.oldWidth;
            this.sidebarSlidOpen = true;

            const showSidebar = true;
            $("body")
              .toggleClass("sidebar--opened", showSidebar)
              .toggleClass("sidebar--closed", !showSidebar);

            const element = document.getElementById("navbar-header");
            if (element) {
              element.style.width = this.sidebarWidth + "%";
            }
          }
        }
      }
    },
    onResize: function (
      panes: Array<{ min: number; max: number; size: number }>
    ) {
      this.sidebarWidth = panes[0].size;
      if (!this.sidebarSlidOpen) {
        const element = document.getElementById("navbar-header");

        if (element) {
          element.style.width = panes[0].size + "%";
        }
      }
    },

    onSplitterClicked: function (nextPane: {
      id: number;
      index: number;
      min: number;
      max: number;
      size: number;
      givenSize: number;
    }) {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: false,
        cancelable: true,
      });

      console.log(nextPane);
      const elements = document.getElementsByClassName("sidebar__toggle");
      if (elements) {
        elements[0].dispatchEvent(event);
      }
      const element = document.getElementById("navbar-header");
      if (element) {
        if (!this.sidebarSlidOpen) {
          if (document.body.classList.contains("sidebar--closed")) {
            element.style.width = "16.66%";
          } else {
            element.style.width = 100 - nextPane.size + "%";
          }
        } else {
          element.style.width = "0%";
        }
      }

      this.sidebarSlidOpen = false;
    },
    collapseSidebar: function () {
      console.log("collapsing");
      if (document.body.classList.contains("sidebar--closed")) {
        this.sidebarWidth = 0;
        const element = document.getElementById("navbar-header");
        if (element) {
          element.style.width = "0%";
        }
      } else {
        this.sidebarWidth = this.oldWidth;
      }
    },
  },
  computed: {
    currentStore: function () {
      return this.$store;
    },
  },
  watch: {
    sidebarWidth: function (newValue, oldValue) {
      this.oldWidth = oldValue;
    },
  },
});
