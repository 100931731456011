
































































































































































































































































































































import Vue from "vue";
import $ from "jquery";

import eventBus from "@/features/EventBus";

import draggable from "vuedraggable";
import ColumnCard from "../components/ProjectColumnCard.vue";
import {
  IKanbanSignQL,
  IKanbanSignType,
  IKanbanSignTypeQL,
  ISigntypeColumn,
  IKanbanProjectUser,
  IKanbanProjectState,
  IKanbanProject,
} from "../types";

import { randomRGB } from "@/features/SmartStates/utilities";

const STATE_TYPES = {
  DESIGN: "0",
  ORDER: "1",
  REVIEW: "2",
};

export default Vue.extend({
  components: { draggable, ColumnCard },
  props: {},
  data: function () {
    return {
      columns: [] as Array<ISigntypeColumn>,
      cardColumnWidth: 1000,
      cardColumnHeight: 500,
      showModal: false,
      showDetailsModal: false,
      column: {
        name: "",
      },
      selectedColumnIndex: 0,
      workflow: "",
      saveError: false,
      saveSuccess: false,
      errorMessage: "",
      project: {} as IKanbanProject,
    };
  },
  mounted: function () {
    this.fetchData();
    window.addEventListener("resize", this.setCardColumnWidth);
    this.setCardColumnWidth();

    //listen for the closingPermissionsUI event on the eventBus
    eventBus.$on("closingPermissionsUI", this.fetchData);
  },
  methods: {
    directProjectPerms: function (
      groupUsers: Array<IKanbanProjectUser>
    ): Array<IKanbanProjectUser> {
      let result = [] as Array<IKanbanProjectUser>;

      if (groupUsers) {
        groupUsers.forEach((user) => {
          if (user.contentType.toLowerCase() === "project") {
            result.push(user);
          }
        });
        return result;
      } else {
        return [];
      }
    },
    directStatePerms: function (
      groupUsers: Array<IKanbanProjectUser>
    ): Array<IKanbanProjectUser> {
      let result = [] as Array<IKanbanProjectUser>;

      if (groupUsers) {
        groupUsers.forEach((user) => {
          if (user.contentType.toLowerCase() === "state") {
            result.push(user);
          }
        });
        return result;
      } else {
        return [];
      }
    },
    setCardColumnWidth: function () {
      this.cardColumnWidth = window.innerWidth - 260 - 126;
      this.cardColumnHeight = window.innerHeight - 210;
    },
    showPermissions: function () {
      // made this an any type because I wasn't able to figure out the typeof globalThis
      /* eslint-disable-next-line */
      const globalThis = window.globalThis as any;
      globalThis.sa_vue.showPermissionsUI({
        model: "project",
        id: Number(this.$route.params.id),
      });
    },
    showColumnPermissions: function () {
      // made this an any type because I wasn't able to figure out the typeof globalThis
      /* eslint-disable-next-line */
      const globalThis = window.globalThis as any;
      globalThis.sa_vue.showPermissionsUI({
        model: "state",
        id: Number(this.sortedColumns[this.selectedColumnIndex].state.id),
      });
    },
    /**
     * redirect user back to kanban board
     * this is done by stripping the last part of the url out
     */
    returnToKanban: function () {
      // get the full path
      // const fullPath = this.$route.fullPath;
      // //remove final /
      // let path = fullPath.substring(0, fullPath.lastIndexOf("/"));
      // // remove final section of path, making sure we have a trailing /
      // path = path.substring(0, path.lastIndexOf("/")) + "/";
      const path = "/builder/kanban/" + this.$route.params.id + "/";
      //redirect user
      location.href = path;
    },
    /**
     * Run a graphQL query
     * @param query string - the graphQL query to run
     * @returns string - a JSON string containing the result data
     */
    runQuery: async function (query: string) {
      const response = await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      });

      const result = await response.json();
      return result;
    },
    /**
     * columnChanged - called whenever a column is dropped
     */
    columnChanged: function (e: {
      moved: { element: HTMLElement; oldIndex: number; newIndex: number };
    }) {
      this.$store.commit("setShowLoadingSpinner", true);
      let queries = [] as Array<string>;

      const orderedColumns = this.columns.sort((a, b) => {
        return a.orderId - b.orderId;
      });

      // the columns array has already been updated with the new positions at this point
      // so we can just read through that array and update the state's order id with the new position
      if (e.moved.newIndex > e.moved.oldIndex) {
        for (let i = e.moved.oldIndex; i < e.moved.newIndex; i++) {
          orderedColumns[i + 2].orderId = i + 1;

          const query = JSON.stringify({
            query: `mutation updateRightColumns {
              mutateState(input:{
                id: "${orderedColumns[i + 2].state.id}",
                stateType: "${STATE_TYPES.DESIGN}",
                workflow: "${this.workflow}",
                name: "${orderedColumns[i + 2].state.name}",
                hexColor: "${orderedColumns[i + 2].state.hexColor}",
                orderId: ${i + 1},
                approved: "Approved",
                rejected: "Rejected",
                needsReviewed: "Review"}) {
                  state{
                    id: contentObjectId
                    name
                    orderId
                  }
                  errors {
                    messages
                  }
                }
              }`,
          });

          queries.push(query);
        }

        orderedColumns[e.moved.oldIndex + 1].orderId = e.moved.newIndex + 1;

        const query = JSON.stringify({
          query: `mutation updateRightColumns {
            mutateState(input:{
              id: "${orderedColumns[e.moved.oldIndex + 1].state.id}",
              stateType: "${STATE_TYPES.DESIGN}",
              workflow: "${this.workflow}",
              name: "${orderedColumns[e.moved.oldIndex + 1].state.name}",
              hexColor: "${
                orderedColumns[e.moved.oldIndex + 1].state.hexColor
              }",
              orderId: ${e.moved.newIndex + 1},
              approved: "Approved",
              rejected: "Rejected",
              needsReviewed: "Review"}) {
                state{
                  id: contentObjectId
                  name
                  orderId
                }
                errors {
                  messages
                }
              }
            }`,
        });

        queries.push(query);
      } else {
        // we are moving a column from right to left
        for (let i = e.moved.oldIndex; i >= e.moved.newIndex + 1; i--) {
          orderedColumns[i].orderId = i + 1;

          const query = JSON.stringify({
            query: `mutation updateLeftToRightColumns {
              mutateState(input:{
                id: "${orderedColumns[i].state.id}",
                stateType: "${STATE_TYPES.DESIGN}",
                workflow: "${this.workflow}",
                name: "${orderedColumns[i].state.name}",
                hexColor: "${orderedColumns[i].state.hexColor}",
                orderId: ${i + 1},
                approved: "Approved",
                rejected: "Rejected",
                needsReviewed: "Review"}) {
                  state{
                    id: contentObjectId
                    name
                    orderId
                  }
                  errors {
                    messages
                  }
                }
              }`,
          });

          queries.push(query);
        }

        orderedColumns[e.moved.oldIndex + 1].orderId = e.moved.newIndex + 1;

        const query = JSON.stringify({
          query: `mutation updateLeftColumns {
            mutateState(input:{
              id: "${orderedColumns[e.moved.oldIndex + 1].state.id}",
              stateType: "${STATE_TYPES.DESIGN}",
              workflow: "${this.workflow}",
              name: "${orderedColumns[e.moved.oldIndex + 1].state.name}",
              hexColor: "${
                orderedColumns[e.moved.oldIndex + 1].state.hexColor
              }",
              orderId: ${e.moved.newIndex + 1},
              approved: "Approved",
              rejected: "Rejected",
              needsReviewed: "Review"}) {
                state{
                  id: contentObjectId
                  name
                  orderId
                }
                errors {
                  messages
                }
              }
            }`,
        });

        queries.push(query);
      }

      // run all the queries
      Promise.all(queries.map(this.runQuery)).then(() => {
        this.$store.commit("setShowLoadingSpinner", false);
        return true;
        // location.reload();
      });
    },
    showError(message: string) {
      this.errorMessage = message;
    },
    getOrCreateSignType: function (
      signType: IKanbanSignTypeQL,
      signTypes: Array<IKanbanSignType>
    ): number {
      const index = signTypes.findIndex((type) => {
        return type.id === signType.id;
      });

      if (index === -1) {
        // sign type not found so we need to create it
        const newSignType = {
          id: signType.id,
          name: signType.name,
          shortCode: signType.shortCode,
          hexColor: signType.hexColor,
          quantity: 1,
          signs: [],
          isExpanded: false,
          globalOrderIdVal: signType.globalOrderIdVal,
        };
        signTypes.push(newSignType);
        return signTypes.length - 1;
      } else {
        return index;
      }
    },
    fetchData: function () {
      // clear old data
      this.project = {
        id: "",
        name: "",
        details: "",
        budgetAmount: 0,
        budgetCurrency: "USD",
        organization: {
          id: "",
          name: "",
          canWhiteLabel: false,
          logoUrl: "",
        },
        groupUsers: [],
      };
      this.columns = [];

      const query = JSON.stringify({
        query: `query get_project {
          project (id: ${this.$route.params.id}) {
            id: contentObjectId
            name
            details
            organization:project {
              id: contentObjectId
              name
              canWhiteLabel: canWhitelabel
              logoSelection {
                imageUrl
              }
            }
            workflows {
              edges {
                node {
                  id: contentObjectId
                }
              }
            }
            groupUsers {
              edges {
                node {
                  user {
                    id:contentObjectId
                    firstName
                    lastName
                    email
                    image
                  }
                  group {
                    name
                    contentType
                  }
                }
              }
            }
            states {
              edges {
                node {
                  id: contentObjectId
                  uuid
                  name
                  hexColor
                  smartStateStatus
                  smartStateIsAuto
                  nextStateAndProject
                  details
                  startDate
                  endDate
                  approved
                  rejected
                  needsReviewed
                  groupUsers {
                    edges {
                      node {
                        user {
                          firstName
                          lastName
                          email
                          image
                        }
                        group {
                          name
                          contentType
                        }
                      }
                    }
                  }
                  stateType
                  orderId
                  signs {
                    edges {
                      node {
                        id: contentObjectId
                        uuid
                        signId
                        quantity
                        facingDirection
                        reviewState
                        artwork
                        state {
                          needsReviewed
                          approved
                          rejected
                        }
                        signType {
                          id: contentObjectId
                          name
                          shortCode
                          hexColor
                        }
                        location {
                          id: contentObjectId
                          name
                          shortCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else {
            this.workflow = result.data.project.workflows.edges[0].node.id;
            this.project.id = result.data.project.id;
            this.project.name = result.data.project.name;
            this.project.details = result.data.project.details;
            this.project.organization.id = result.data.project.organization.id;
            this.project.organization.name =
              result.data.project.organization.name;
            this.project.organization.canWhiteLabel =
              result.data.project.organization.canWhiteLabel;
            if (result.data.project.organization.logoSelection) {
              this.project.organization.logoUrl =
                result.data.project.organization.logoSelection.imageUrl;
            } else {
              this.project.organization.logoUrl = "";
            }

            this.project.groupUsers = [];

            result.data.project.groupUsers.edges.forEach(
              (user: {
                node: {
                  user: {
                    id: string;
                    firstName: string;
                    lastName: string;
                    email: string;
                    image: string;
                  };
                  group: {
                    name: string;
                    contentType: string;
                  };
                };
              }) => {
                this.project.groupUsers.push({
                  id: user.node.user.id,
                  firstName: user.node.user.firstName,
                  lastName: user.node.user.lastName,
                  email: user.node.user.email,
                  image: user.node.user.image,
                  contentType: user.node.group.contentType,
                  groupName: user.node.group.name,
                });
              }
            );

            result.data.project.states.edges.forEach(
              (state: IKanbanProjectState) => {
                // set the next state and project fields
                let nextState = "";
                let nextProject = "";
                if (state.node.nextStateAndProject !== "") {
                  const nextStateAndProject = JSON.parse(
                    state.node.nextStateAndProject
                  );
                  nextState = nextStateAndProject.nextState;
                  nextProject = nextStateAndProject.nextProject;
                }
                const newColumn = {
                  state: {
                    id: state.node.id,
                    name: state.node.name,
                    startDate: state.node.startDate,
                    endDate: state.node.endDate,
                    uuid: state.node.uuid,
                    groupUsers: [],
                    workflow: this.workflow,
                    hexColor: state.node.hexColor,
                    approved: state.node.approved,
                    rejected: state.node.rejected,
                    needsReviewed: state.node.needsReviewed,
                    details: state.node.details,
                    type: state.node.stateType,
                    smartStateStatus: state.node.smartStateStatus.substring(2),
                    smartStateIsAuto: state.node.smartStateIsAuto,
                    nextState: nextState,
                    nextProject: nextProject,
                  },
                  orderId: state.node.orderId,
                  groupUsers: [],
                  sections: [],
                  signTypes: [],
                  isLoading: true,
                } as ISigntypeColumn;

                state.node.groupUsers.edges.forEach(
                  (user: {
                    node: {
                      user: {
                        id: string;
                        firstName: string;
                        lastName: string;
                        email: string;
                        image: string;
                      };
                      group: {
                        name: string;
                        contentType: string;
                      };
                    };
                  }) => {
                    newColumn.groupUsers.push({
                      id: user.node.user.id,
                      firstName: user.node.user.firstName,
                      lastName: user.node.user.lastName,
                      email: user.node.user.email,
                      image: user.node.user.image,
                      contentType: user.node.group.contentType,
                      groupName: user.node.group.name,
                    });
                  }
                );

                state.node.signs.edges.forEach(
                  (sign: { node: IKanbanSignQL }) => {
                    let signTypeIndex = this.getOrCreateSignType(
                      sign.node.signType,
                      newColumn.signTypes
                    );
                    newColumn.signTypes[signTypeIndex].signs.push({
                      signId: sign.node.signId,
                      id: sign.node.id,
                      facingDirection: sign.node.facingDirection,
                      reviewState: sign.node.reviewState,
                      quantity: sign.node.quantity,
                      artwork: sign.node.artwork,
                      hexColor: sign.node.signType.hexColor,
                      imageURL: "/sign/" + sign.node.id + "/svg_as_png/",
                      messages: [],
                      repeatingMessages: [],
                      details: [],
                      attachments: [],
                      signType: {
                        id: newColumn.signTypes[signTypeIndex].id,
                        shortCode: newColumn.signTypes[signTypeIndex].shortCode,
                        name: newColumn.signTypes[signTypeIndex].name,
                        hexColor: newColumn.signTypes[signTypeIndex].hexColor,
                        quantity: newColumn.signTypes[signTypeIndex].quantity,
                        globalOrderIdVal:
                          newColumn.signTypes[signTypeIndex].globalOrderIdVal,
                        isExpanded:
                          newColumn.signTypes[signTypeIndex].isExpanded,
                      },
                      location: {
                        id: sign.node.location.id,
                        shortCode: sign.node.location.name,
                        name: sign.node.location.shortCode,
                      },
                      state: {
                        needsReviewed: sign.node.state.needsReviewed,
                        approved: sign.node.state.approved,
                        rejected: sign.node.state.rejected,
                      },
                    });
                  }
                );

                this.columns.push(newColumn);
              }
            );
          }
        });
    },
    showAddColumnModal: function () {
      // create the state via graphQL
      $("#addColumnModal").modal("show");
      this.showModal = true;
      // this.$store.commit("addColumn", this.$route.params.id);
    },
    closeModal: function () {
      $("#addColumnModal").modal("hide");
      this.showModal = false;
    },
    editColumn: function (columnIndex: number) {
      this.selectedColumnIndex = columnIndex;
      this.showDetailsModal = true;
      //$("#editProjectColumnModal").modal("show");
    },
    /**
     * addColumn - moves the last column over one to the right and inserts
     *              the new column in the second last position. We do this
     *              because the last state is a smart state and must remain
     *              the last state
     */
    addColumn: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      // first move the last column over one position to the right
      let queryString = `mutation StateMutation {
        mutateState(input: {
          id: ${this.sortedColumns[this.sortedColumns.length - 1].state.id},
          stateType: "${STATE_TYPES.DESIGN}",
          workflow: "${this.workflow}",
          name: "${
            this.sortedColumns[this.sortedColumns.length - 1].state.name
          }",
          hexColor: "${
            this.sortedColumns[this.sortedColumns.length - 1].state.hexColor
          }",
          orderId: ${this.sortedColumns.length},
          approved: "Approved",
          rejected: "Rejected",
          needsReviewed: "Review"}) {
          state{
            id: contentObjectId
          }
          errors {
            messages
          }
        }
      }`;

      let query = JSON.stringify({ query: queryString });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveError = true;
            this.saveSuccess = false;
            this.errorMessage = result.errors[0].message;
            this.$store.commit("setShowLoadingSpinner", false);
          } else {
            // now we can insert the new column
            queryString = `mutation StateMutation{
                  mutateState(input:{
                    stateType: "${STATE_TYPES.DESIGN}",
                    workflow: "${this.workflow}",
                    name: "${this.column.name}",
                    hexColor: "${randomRGB()}",
                    orderId: ${this.columns.length - 1},
                    approved: "Approved",
                    rejected: "Rejected",
                    needsReviewed: "Review"}) {
                      state{
                        id: contentObjectId
                      }
                    }
                  }`;

            query = JSON.stringify({ query: queryString });

            fetch("/graphql/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: query,
            })
              .then((res) => res.json())
              .then((result) => {
                if (result.errors) {
                  this.saveError = true;
                  this.saveSuccess = false;
                  this.errorMessage = result.errors[0].message;
                  this.$store.commit("setShowLoadingSpinner", false);
                } else {
                  this.saveSuccess = true;
                  this.saveError = false;
                  this.$store.commit("setShowLoadingSpinner", false);
                  location.reload();
                }
              });
          }
        });
    },
    saveChanges: function () {
      let queries = [] as Array<string>;
      this.sortedColumns.forEach((column, index) => {
        this.selectedColumnIndex = index;
        queries.push(this.buildColumnQuery());
      });

      // run all the queries
      Promise.all(queries.map(this.runQuery)).then(() => {
        this.$store.commit("setShowLoadingSpinner", false);
        this.$router.go(-1);
        // location.reload();
      });
    },
    buildColumnQuery: function () {
      let queryString = `mutation StateMutation {
        mutateState(input: {
          id: ${this.sortedColumns[this.selectedColumnIndex].state.id}
          stateType: "${STATE_TYPES.DESIGN}",
          smartStateIsAuto: ${
            this.sortedColumns[this.selectedColumnIndex].state.smartStateIsAuto
          }
          workflow: "${
            this.sortedColumns[this.selectedColumnIndex].state.workflow
          }",
          name: "${this.sortedColumns[this.selectedColumnIndex].state.name}",
          hexColor: "${
            this.sortedColumns[this.selectedColumnIndex].state.hexColor
          }",
          orderId: ${this.sortedColumns[this.selectedColumnIndex].orderId},
          details: "${
            this.sortedColumns[this.selectedColumnIndex].state.details
          }",
          startDate: ${
            this.columns[this.selectedColumnIndex].state.startDate
              ? '"' +
                this.columns[this.selectedColumnIndex].state.startDate +
                '"'
              : null
          },
          endDate: ${
            this.columns[this.selectedColumnIndex].state.endDate
              ? '"' + this.columns[this.selectedColumnIndex].state.endDate + '"'
              : null
          },
          approved: "Approved",
          rejected: "Rejected",
          needsReviewed: "Review"}) {
          state{
            id: contentObjectId
          }
        }
      }`;

      return JSON.stringify({ query: queryString });
    },
    /**
     * saveColumn - save the column details
     */
    saveColumn: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      // first move the last column over one position to the right
      let query = this.buildColumnQuery();

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveError = true;
            this.saveSuccess = false;
            this.errorMessage = result.errors[0].message;
            this.$store.commit("setShowLoadingSpinner", false);
          } else {
            this.saveSuccess = true;
            this.saveError = false;
            this.$store.commit("setShowLoadingSpinner", false);
            this.showDetailsModal = false;
          }
        });
    },
  },
  computed: {
    notSmartStateColumns: function (): Array<ISigntypeColumn> {
      const columns = this.columns.filter((column, index) => {
        return index !== 0 && index !== this.columns.length - 1;
      });

      return columns.sort((a, b) => {
        return a.orderId - b.orderId;
      });
    },
    sortedColumns: function (): Array<ISigntypeColumn> {
      const columns = this.columns;
      return columns.sort((a, b) => {
        return a.orderId - b.orderId;
      });
    },
  },
});
