


















































































import Vue from "vue";
import {
  Stripe,
  StripeAddressElement,
  StripeElements,
  PaymentMethod,
} from "@stripe/stripe-js";
import { IBuilder } from "@/features/Orders/types";
import { IPaymentMethod } from "@/features/CompanySettings/types";

import PaymentInfoTile from "@/features/CompanySettings/components/PaymentInfoTile.vue";

export default Vue.extend({
  components: { PaymentInfoTile },
  data: function () {
    return {
      stripe: null as Stripe | null,
      addressElement: {} as StripeAddressElement,
      builder: {} as IBuilder,
      saveFailed: false,
      saveSucceeded: false,
      saveErrorMessage: "",
      saveErrorCode: 200,
      step: 1,
      totalSteps: 2,
      paymentMethods: [] as Array<IPaymentMethod>,
    };
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    fetchData: function () {
      const query = JSON.stringify({
        query: `
        query getBuilder {
          builder(id:${this.$route.params.id}) {
            name
            company {
              id:contentObjectId
              countryCode
              state
              address1
              address2
              city
              postalCode
              phone
              billing {
                paymentMethods {
                  cardType:brand
                  last4
                  default
                  expYear
                  expMonth
                  stripeId
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveFailed = true;
            this.saveSucceeded = false;
            this.saveErrorMessage = result.errors[0].message;
          } else {
            this.saveFailed = false;
            this.saveSucceeded = true;
            this.saveErrorMessage = "";
            this.saveErrorCode = 200;
            this.builder.name = result.data.builder.name;
            this.builder.companyId = result.data.builder.company.id;
            this.builder.street1 = result.data.builder.company.address1;
            this.builder.street2 = result.data.builder.company.address2;
            this.builder.city = result.data.builder.company.city;
            this.builder.state = result.data.builder.company.state;
            this.builder.postalCode = result.data.builder.company.postalCode;
            this.builder.country = result.data.builder.company.countryCode;
            this.builder.phone = result.data.builder.company.phone;

            this.paymentMethods =
              result.data.builder.company.billing.paymentMethods;

            this.loadAddressElement();
          }
        });
    },
    saveChanges: function () {
      this.addressElement.getValue().then((value) => {
        if (value.complete) {
          this.builder.name = value.value.name;
          this.builder.street1 = value.value.address.line1;
          this.builder.street2 = value.value.address.line2;
          this.builder.city = value.value.address.city;
          this.builder.state = value.value.address.state;
          this.builder.postalCode = value.value.address.postal_code;
          this.builder.country = value.value.address.country;
          this.builder.phone = value.value.phone;

          if (!this.builder.street2) {
            this.builder.street2 = "";
          }

          let query = "";
          query = JSON.stringify({
            query: `
              mutation {
                mutateBuilder(input: {
                  id: ${this.$route.params.id},
                  name: "${this.builder.name}",
                }) {
                  builder {
                    name
                  }
                }
              }
            `,
          });

          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: query,
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.errors) {
                this.saveFailed = true;
                this.saveSucceeded = false;
                this.saveErrorMessage = result.errors[0].message;
              } else {
                this.saveFailed = false;
                this.saveSucceeded = true;
                this.saveErrorMessage = "";
                // if (this.userMode === "create") {
                //   window.location.href =
                //     result.data.mutateBuilder.builder.stripeConnectAccountSetupUrl;
                // }

                query = JSON.stringify({
                  query: `
                    mutation {
                      mutateCompany(input: {
                        id: ${this.builder.companyId},
                        name: "${this.builder.name}",
                        address1: "${this.builder.street1}",
                        address2: "${this.builder.street2}",
                        city: "${this.builder.city}",
                        postalCode: "${this.builder.postalCode}",
                        countryCode: "${this.builder.country}",
                        state: "${this.builder.state}",
                        phone: "${this.builder.phone || ""}",
                      }) {
                        company {
                          name
                        }
                        errors {
                          messages
                        }
                      }
                    }
                  `,
                });

                fetch("/graphql/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: query,
                })
                  .then((res) => res.json())
                  .then((result) => {
                    if (result.errors) {
                      this.saveFailed = true;
                      this.saveSucceeded = false;
                      this.saveErrorMessage = result.errors[0].message;
                    } else {
                      this.saveFailed = false;
                      this.saveSucceeded = true;
                      this.saveErrorMessage = "";
                      this.saveErrorCode = 200;

                      this.step++;
                    }
                  });
              }
            });
        }
      });
    },
    returnToCaller: function () {
      if (this.$route.query.next) {
        window.location.href = this.$route.query.next as string;
      }
    },
    loadAddressElement: async function () {
      if (!this.stripe) {
        if (window.Stripe) {
          if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
            this.stripe = window.Stripe(
              process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
            );
          }
        }
      }

      // have to use any type on next line because Stripe's typescript support
      // is missing this type
      const appearance = {
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily:
              "'Suisse', 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            colorText: "#333333",
            fontSizeBase: "13px",
            fontSizeSm: "13px",
            fontWeightNormal: 500,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      let elements = {} as StripeElements;

      if (this.stripe) {
        elements = this.stripe.elements(appearance);
      }

      if (elements) {
        // Create and mount the Address Element in shipping mode
        this.addressElement = elements.create("address", {
          mode: "billing",
          display: {
            name: "organization",
          },
          fields: {
            phone: "always",
          },
          defaultValues: {
            name: this.builder.name,
            phone: this.builder.phone,
            address: {
              line1: this.builder.street1,
              line2: this.builder.street2,
              city: this.builder.city,
              state: this.builder.state,
              postal_code: this.builder.postalCode,
              country: this.builder.country ? this.builder.country : "US",
            },
          },
        });
        this.addressElement.mount("#address-element");
      }
    },
    cardAdded: function (paymentMethod: PaymentMethod) {
      // refresh the payment methods
      const query = JSON.stringify({
        query: `
                    mutation attach_payment_method {
                      attachPaymentMethod(input: {
                        companyId: ${this.builder.companyId},
                        paymentMethodId: "${paymentMethod.id}",
                      }) {
                        # errors { messages }
                        success
                      }
                    }`,
      });
      // attach payment method on backend
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          if (result.errors) {
            console.log(result.errors[0].message);
          }
          // else if (
          //   result.data.attachPaymentMethod?.errors.length > 0
          // ) {
          //   this.errorMessage =
          //     result.data.attachPaymentMethod.errors[0].messages.join(
          //       ", "
          //     );
          // }
          else {
            this.fetchData();
          }
        });
    },
  },
  computed: {
    defaultPaymentMethod: function (): IPaymentMethod | undefined {
      return this.paymentMethods.find((paymentMethod) => {
        return paymentMethod.default;
      });
    },
  },
});
