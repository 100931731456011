




























































































































import Vue from "vue";
import { PropType } from "vue";

import eventBus from "@/features/EventBus";

import { IKanbanProject, IKanbanProjectUser } from "../types";

export default Vue.extend({
  props: {
    project: {
      type: Object as PropType<IKanbanProject>,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      errorMessage: "",
      saveError: false,
    };
  },
  mounted: function () {
    eventBus.$on("closingPermissionsUI", this.permissionsUIClosing);
  },
  methods: {
    directProjectPerms: function (
      groupUsers: Array<IKanbanProjectUser>
    ): Array<IKanbanProjectUser> {
      let result = [] as Array<IKanbanProjectUser>;

      if (groupUsers) {
        groupUsers.forEach((user) => {
          if (user.contentType.toLowerCase() === "project") {
            result.push(user);
          }
        });
        return result;
      } else {
        return [];
      }
    },
    permissionsUIClosing: function () {
      this.$emit("refreshRequired");
    },
    showPermissions: function () {
      // made this an any type because I wasn't able to figure out the typeof globalThis
      /* eslint-disable-next-line */
      const globalThis = window.globalThis as any;
      globalThis.sa_vue.showPermissionsUI({
        model: "project",
        id: Number(this.$route.params.id),
      });
    },
    saveChanges: function () {
      const query = JSON.stringify({
        query: `mutation {
        mutateProject(input:{id: ${this.project.id}, project: ${this.project.organization.id}, name: "${this.project.name}", budgetAmount: ${this.project.budgetAmount}}) {
          errors {
            messages
          }
        }
      }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveError = true;
            this.errorMessage = result.errors.messsages;
          } else {
            this.errorMessage = "Builder save successful";
            this.$emit("closeClicked");
          }
        });
    },
  },
});
