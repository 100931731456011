var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.signType)?_c('div',[_c('div',{staticStyle:{"border":"1px solid #ccc","border-radius":"8px","background-color":"#f5f6f7","border-left-width":"3px"},style:({ 'border-left-color': '#' + _vm.signType.hexColor })},[_c('div',{staticStyle:{"display":"flex"},style:({
        'border-bottom': _vm.signType.isExpanded ? '1px solid #ccc' : 'none',
      })},[_c('div',{staticStyle:{"padding":"5px"}},[_c('i',{staticClass:"fa",class:_vm.signType.isExpanded ? 'fa-caret-down' : 'fa-caret-right',staticStyle:{"cursor":"default"},on:{"click":_vm.expandSigntype}})]),_c('div',{staticStyle:{"flex-grow":"1","display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('span',{staticStyle:{"padding-left":"3px","padding-right":"3px","display":"inline","margin-right":"7px"},style:({
              backgroundColor: '#' + _vm.signType.hexColor,
              color: _vm.getTextColor(_vm.signType.hexColor),
            })},[_vm._v(_vm._s(_vm.signType.shortCode ? _vm.signType.shortCode.trim() : ""))]),_vm._v(" "+_vm._s(_vm.signType.name)+" "),_c('span',{staticClass:"counts_wrapper",attrs:{"title":'Showing ' + _vm.signType.signs.length + ' signs'}},[_c('span',{staticClass:"counts_total",staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(_vm.calculateSignCount())+" ")])])]),_c('div',{staticStyle:{"padding":"5px","cursor":"default","margin-right":"1px"}},[_c('i',{staticClass:"fa fa-arrow-down-to-line",staticStyle:{"margin-right":"3px"},on:{"click":function($event){return _vm.downloadSigns(_vm.signType.id)}}}),_c('i',{staticClass:"fa fa-angles-right",on:{"click":function($event){return _vm.moveAllSigns(_vm.signType.id)}}})])])]),_c('div',{key:'detail_' + _vm.signType.id,staticClass:"panel-body",class:_vm.signType.isExpanded ? '' : 'collapse',staticStyle:{"max-width":"385px"},attrs:{"id":'panel_' + _vm.signType.id}},[(_vm.signType.signs.length > 0)?_c('div',{staticStyle:{"padding-left":"8px"}},_vm._l((_vm.chunkSignsArray(_vm.signType.signs, _vm.gridSize)),function(chunk){return _c('div',{key:chunk[0].id,staticClass:"row"},_vm._l((chunk),function(sign,signIndex){return _c('div',{key:signIndex,class:'col-md-' + 12 / _vm.gridSize,staticStyle:{"cursor":"move"},attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.dragStart($event, sign)}}},[_c('div',{staticStyle:{"padding-left":"3px","padding-right":"3px","display":"inline-block","margin-bottom":"6px"},style:({
                backgroundColor: '#' + _vm.signType.hexColor,
                color: _vm.getTextColor(_vm.signType.hexColor),
              })},[_vm._v(" "+_vm._s(sign.signId)+" ")]),(sign.artwork)?_c('img',{staticStyle:{"width":"100%","border":"1px solid #e8e8e8"},attrs:{"src":'/sign/' + sign.id + '/svg_as_png/',"alt":sign.id,"draggable":"false"},on:{"click":function($event){return _vm.showSignDetails(_vm.signType.signs, sign.signId)}}}):_c('img',{staticStyle:{"width":"100%","border":"1px solid #e8e8e8"},attrs:{"src":require('../assets/no_image_placeholder.png'),"alt":sign.id},on:{"click":function($event){return _vm.showSignDetails(_vm.signType.signs, sign.signId)}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"center"}},[(sign.quantity > 1)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" Qty: "+_vm._s(sign.quantity)+" ")]):_vm._e()])])])}),0)}),0):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }