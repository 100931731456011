

















































































































import Vue from "vue";
import { IOrganization, IOrganizationQL } from "../types";

enum OrgStatus {
  MANAGED = 1,
  UNMANAGED = 2,
  PENDING_INVITE = 3,
}

export default Vue.extend({
  data: function () {
    return {
      organizations: [] as Array<IOrganization>,
      sortColumn: "name",
      sortDirection: "asc",
      searchText: "",
      orgType: "all",
      showProcessing: false,
      hasNextPage: false,
      endCursor: "",
      totalOrganizations: 0,
      tableHeight: 300,
    };
  },
  mounted: function () {
    this.fetchOrganizations();
    window.addEventListener("resize", this.resizeTable);
    this.resizeTable();
  },
  methods: {
    resizeTable: function () {
      this.tableHeight = window.innerHeight - 150;
    },

    buildOrderByString: function (): string {
      let result = "";
      if (this.sortDirection === "desc") {
        result = "-";
      }

      switch (this.sortColumn.toLowerCase()) {
        case "name":
          result += "name";
          break;
        case "owner":
          result += "organization_owner__email";
          break;
      }
      return result;
    },
    freshFetchOrganizations: function () {
      this.organizations = [];
      this.endCursor = "";
      this.hasNextPage = false;
      this.fetchOrganizations();
    },
    fetchOrganizations: function () {
      this.showProcessing = true;

      const orderBy = this.buildOrderByString();

      const nameContains =
        this.searchText !== ""
          ? `, name_Istartswith: "${this.searchText}"`
          : "";

      const query = JSON.stringify({
        query: `query get_orgs{
          organizations (after: "${this.endCursor}", orderBy: "${orderBy}" ${nameContains}) {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edges {
              node {
                id:contentObjectId
                name
                organizationOwner
                hasPendingInvite
                hasManageProjects
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          this.showProcessing = false;
          if (result.errors) {
            alert(result.errors);
          } else {
            this.hasNextPage = result.data.organizations.pageInfo.hasNextPage;
            this.endCursor = result.data.organizations.pageInfo.endCursor;
            this.totalOrganizations = result.data.organizations.totalCount;

            result.data.organizations.edges.forEach(
              (edge: { node: IOrganizationQL }) => {
                const newOrg = {
                  id: edge.node.id,
                  name: edge.node.name,
                  organizationOwner: edge.node.organizationOwner,
                  hasPendingInvite: edge.node.hasPendingInvite,
                  hasManageProjects: edge.node.hasManageProjects,
                  status: edge.node.hasPendingInvite
                    ? OrgStatus.PENDING_INVITE
                    : edge.node.organizationOwner !== ""
                    ? OrgStatus.UNMANAGED
                    : OrgStatus.MANAGED,
                } as IOrganization;

                this.organizations.push(newOrg);
              }
            );
            const el = document.getElementById("dashboard-table-body");
            if (el) {
              el.addEventListener("scroll", this.userScrolled);
            }
          }
        });
    },
    userScrolled: function () {
      const el = document.getElementById("dashboard-table-body");
      if (this.hasNextPage) {
        if (el) {
          // the 50 is a buffer so that we don't have to scroll to the very bottom
          if (el.scrollTop + el.clientHeight >= el.scrollHeight - 50) {
            // turn off next page so that this.fetchData doesn't get called multiple times
            // it will reset by fetchData once the data is retrieved
            this.hasNextPage = false;
            this.fetchOrganizations();
          }
        }
      }
    },
    sortOrgsByName: function () {
      if (this.sortColumn === "name") {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "name";
        this.sortDirection = "asc";
      }
    },
    sortOrgsByOwner: function () {
      if (this.sortColumn === "owner") {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "owner";
        this.sortDirection = "asc";
      }
    },
    filteredOrgs: function () {
      let result = [] as Array<IOrganization>;

      this.organizations.forEach((organization) => {
        if (this.orgType === "all") {
          result.push(organization);
        } else if (this.orgType === "0") {
          // managed orgs only
          if (organization.organizationOwner) {
            result.push(organization);
          }
        } else {
          // unmanaged orgs
          if (!organization.organizationOwner) {
            result.push(organization);
          }
        }
      });
      return result;
    },
  },
  computed: {},
  watch: {
    searchText: function () {
      this.freshFetchOrganizations();
    },
    sortDirection: function () {
      // if we haven't loaded all the data yet then fetch from the server
      this.freshFetchOrganizations();
    },
    sortColumn: function () {
      // if we haven't loaded all the data yet then fetch from the server
      this.freshFetchOrganizations();
    },
  },
});
