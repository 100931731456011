


























import Vue from "vue";
import {
  ISignTypeNode,
  ITreeData,
  ISignTypeEdge,
  ISignTypeFoldersEdge,
  ISignTypeFoldersChildrenEdge,
  ISignTypeFoldersChildren,
  ISignTypeFoldersSignTypeEdge,
} from "../types";
import SignListTree from "../components/SignListTree.vue";
import CollapsibleSection from "../components/CollapsibleSection.vue";
import { SignFoldersList } from "../include/DBSupport";
import SignTypesConfirmModal from "./modals/SignTypesConfirmModal.vue";

export default Vue.extend({
  props: { item: Object, indent: Number },
  components: { SignListTree, CollapsibleSection, SignTypesConfirmModal },
  data: function () {
    return {
      signTypes: [] as Array<ISignTypeNode>,
      treeData: [] as Array<ITreeData>,
      treeItem: {} as ITreeData,
    };
  },
  mounted: function () {
    this.$root.$refs.signTypesList = this;
  },
  methods: {
    /**
     * recusrively process children
     * @param {ISignTypeFoldersChildren} data - the data to process
     * @param {ITreeData} element - the parent element
     */
    processChildren(data: ISignTypeFoldersChildren, element: ITreeData) {
      data.edges.forEach((edge: ISignTypeFoldersChildrenEdge) => {
        //only process the top level elements (i.e. those with a parent of null)
        element.children.push({
          id: edge.node.id,
          uuid: edge.node.uuid || edge.node.id,
          name: edge.node.name,
          isOpen: false,
          children: [],
          shortCode: "",
          hexColor: "",
        } as ITreeData);
        if (edge.node.children.edges.length > 0) {
          this.processChildren(
            edge.node.children,
            element.children[element.children.length - 1]
          );
        }
        if (edge.node.signTypes.edges.length > 0) {
          edge.node.signTypes.edges.forEach(
            (edge2: ISignTypeFoldersSignTypeEdge) => {
              element.children[element.children.length - 1].children.push({
                id: edge2.node.id,
                uuid: edge2.node.uuid || edge2.node.id,
                name: edge2.node.name,
                isOpen: false,
                children: [],
                shortCode: edge2.node.shortCode,
                hexColor: edge2.node.hexColor,
              } as ITreeData);
            }
          );
        }
      });
    },
    /**
     * handle the sign type clicked event
     * @param {ITreeData} treeData - the item that was clicked on
     */
    signTypeClicked: function (treeData: ITreeData) {
      this.treeItem = treeData;
      if (this.$store.getters.previousSignTypeUUID === this.treeItem.uuid) {
        // user selected the same sign type as they were working on before
        this.$store.commit("setCurrentSignTypeUUID", this.treeItem.uuid);
      } else {
        if (this.$store.getters.isChanged) {
          this.$store.commit("setShowSignTypesConfirmModal", true);
        } else {
          this.$store.commit("setCurrentSignTypeUUID", this.treeItem.uuid);
          this.$nextTick(function () {
            this.$store.commit("selectSignTypeById", this.treeItem.uuid);
            this.$store.commit("setSignTypeName", this.treeItem.name);
          });
        }
      }

      this.$nextTick(function () {
        this.$emit("signTypeClicked");
      });
    },
    /**
     * handle the plus clicked event
     */
    addSignType: function () {
      this.$store.commit("setShowAddSignTypeModal", true);
    },
    saveChanges: function () {
      if (this && this.$root && this.$root.$refs && this.$root.$refs.TopBar) {
        /* eslint-disable-next-line */
        (this.$root.$refs.TopBar as any).saveSignType().then(() => {
          this.$store.commit("setCurrentSignTypeUUID", this.treeItem.uuid);
          this.$nextTick(function () {
            this.$store.commit("selectSignTypeById", this.treeItem.uuid);
            this.$store.commit("setSignTypeName", this.treeItem.name);
          });
        });
      }
    },
    discardChanges: function () {
      this.$store.commit("setIsChanged", false);
      this.$store.commit("setCurrentSignTypeUUID", this.treeItem.uuid);
      this.$nextTick(function () {
        this.$store.commit("selectSignTypeById", this.treeItem.uuid);
        this.$store.commit("setSignTypeName", this.treeItem.name);
      });
    },
    loadSignFolders: function () {
      // build the tree
      this.$store.commit("setShowLoadingSpinner", true);

      let localTreeData = [] as Array<ITreeData>;

      const query = SignFoldersList(
        this.$store.getters.currentOrganizationUUID
      );

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (
            data.data.organization &&
            data.data.organization.signTypeFolders
          ) {
            data.data.organization.signTypeFolders?.edges.forEach(
              (edge: ISignTypeFoldersEdge) => {
                //only process the top level elements (i.e. those with a parent of null)
                if (!edge.node.parent) {
                  localTreeData.push({
                    id: edge.node.id,
                    uuid: edge.node.uuid || edge.node.id,
                    name: edge.node.name,
                    isOpen: false,
                    children: [],
                    shortCode: "",
                    hexColor: "",
                  } as ITreeData);
                  if (edge.node.children.edges.length > 0) {
                    this.processChildren(
                      edge.node.children,
                      localTreeData[localTreeData.length - 1]
                    );
                  }
                }
              }
            );

            data.data.organization.signTypes?.edges.forEach(
              (edge: ISignTypeEdge) => {
                if (edge.node.folder === null) {
                  localTreeData.push({
                    id: edge.node.id,
                    uuid: edge.node.uuid || edge.node.id,
                    name: edge.node.name,
                    isOpen: false,
                    children: [],
                    shortCode: edge.node.shortCode,
                    hexColor: edge.node.hexColor,
                  } as ITreeData);
                }
              }
            );
          }

          this.treeData = JSON.parse(JSON.stringify(localTreeData));
          this.$store.commit("setShowLoadingSpinner", false);
        });
    },
  },
  computed: {
    /**
     * is this item a folder? (i.e. does it have children)
     * @returns {boolean} True if item is a folder
     */
    isFolder: function (): boolean {
      if (this.item.children && this.item.children.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * should the section be displayed open
     * (in this case it will always be open)
     * @returns {boolean} True if section should be displayed open
     */
    openSection: function () {
      return true;
    },
    currentSignTypeUUID: function (): string {
      if (this.$store.getters.previousSignTypeUUID !== "") {
        this.loadSignFolders();
      }
      return this.$store.getters.currentSignTypeUUID;
    },
  },
  watch: {
    currentSignTypeUUID: function () {
      this.loadSignFolders();
    },
  },
});
