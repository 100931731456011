import Vue from "vue";
import VueRouter from "vue-router";

import { fullPageRoutes } from "@/routes";
import store from "@/store";
import { SessionState } from "@/store/modules/session";

/**
 * We call this from Django's full page template to enable the router for full page views
 *
 * @param element - the element we should attach to
 * @param stateValues - the stateValues object to populate the store with
 */
export default function mountFullPageRoutes(
  element: HTMLElement,
  stateValues: {
    session?: SessionState;
  }
): void {
  Vue.use(VueRouter);

  if (typeof stateValues.session === "object") {
    store.commit(
      "setSessionIsAuthenticated",
      stateValues.session.isAuthenticated
    );
    store.commit("setSessionUserId", stateValues.session.userId);
    store.commit("setSessionEmail", stateValues.session.email);
    store.commit("setSessionProfileId", stateValues.session.profileId);
    store.commit("setSessionFirstName", stateValues.session.firstName);
    store.commit("setSessionLastName", stateValues.session.lastName);
    store.commit("setSessionCompany", stateValues.session.company);
    store.commit("setSessionFeatureFlags", stateValues.session.featureFlags);
    store.commit("setIsBuilder", stateValues.session.isBuilder);
    store.commit("setIsInstaller", stateValues.session.isInstaller);
    store.commit("setIsDesigner", stateValues.session.isDesigner);
    store.commit("setIsSuperUser", stateValues.session.isSuperUser);
    store.commit(
      "setIsOnBuildFreeTrial",
      stateValues.session.isOnBuildFreeTrial
    );
    store.commit(
      "setSessionPendingOwnerInvites",
      stateValues.session.pendingOwnerInvites
    );
  }

  const router = new VueRouter({
    mode: "history",
    routes: fullPageRoutes,
  });

  // user must be logged in unless all routes' meta.requiresAuthentication === false
  router.beforeEach((to, from, next) => {
    if (
      store.state.session.isAuthenticated === true ||
      !to.matched.some(
        (routeRecord) => routeRecord.meta.requiresAuthentication !== false
      )
    ) {
      next();
    } else {
      // redirect to /accounts/login/?next=...
      const loginPage = new URL("/accounts/login/", window.location.toString());
      loginPage.search = new URLSearchParams({ next: to.fullPath }).toString();
      window.location.replace(loginPage.toString());

      next(false);
    }
  });

  new Vue({
    store,
    template: "<router-view/>",
    router,
  }).$mount(element);
}
