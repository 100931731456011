export const ORDER_STATUS: { [key: string]: string } = {
  DRAFT: "0",
  QUOTING: "1",
  IN_REVIEW: "2",
  QUOTE_ACCEPTED: "3",
  PENDING_OFFLINE_PAYMENT: "4",
  PENDING_ONLINE_PAYMENT: "5",
  FAILED_PAYMENT: "6",
  BUILDER_PENDING_PAYMENT: "7",
  RECEIVED_PAYMENT: "8",
  IN_PROGRESS: "9",
  SHIPPED: "10",
  COMPLETE: "11",
  CANCEL: "12",
};

/**
 * Represents an order.
 *
 * @interface IOrder
 */
export interface IOrder {
  id: string;
  uuid: string;
  name: string;
  submittedDate: Date;
  dueDate: Date | null;
  amount: number;
  currency: string;
  status: string;
  stateId: number;
  stateName: string;
  poNumber: string;
  lastSaved: Date | null;
  manager: IOrderManager;
  builder: {
    id: string;
    name: string;
    email: string;
  };
  builderOrder: {
    id: string;
  };
  userIsBuilder: boolean;
  signTypes: Array<IOrderSignType>;
  itemSubtotal: number;
  shipping: number;
  taxes: {
    name: string;
    amount: number;
  };
  billingSameAsShipping: boolean;
  shippingStreet1: string;
  shippingStreet2: string;
  shippingCity: string;
  shippingState: string;
  shippingPostalCode: string;
  shippingCountry: string;
  shippingContact: string;
  billingStreet1: string;
  billingStreet2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountry: string;
  billingContact: string;
  includeInstall: boolean;
  IncludeBuild: boolean;
  comments: Array<IComment>;
  projects: Array<IOrderProject>;
  organization: {
    id: string;
    name: string;
  };
}

export interface IOrderManager {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}
export interface IOrderProject {
  name: string;
  phaseType: string;
}

export interface IComment {
  commentId: string;
  message: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  userPic: string;
}

export interface ICommentQl {
  node: {
    id: string;
    message: string;
    user: {
      userId: string;
      firstName: string;
      lastName: string;
      pic: string;
    };
  };
}

export interface IOrderSignType {
  id: string;
  orderItemId: number;
  quantity: number;
  name: string;
  shortCode: string;
  hexColor: string;
  isExpanded: boolean;
  isRemoveExpanded: boolean;
  install_price: number;
  actual_price: number;
  currency: string;
  orderLineItemId: string;
  orderId: number;
  globalOrderIdVal: string;
  folderOrderId: number;
  signsLoaded: boolean;
  signs: Array<IOrderSignTypeSign>;
}

export interface IOrderSignTypeSign {
  id: string;
  artwork: string | null;
  quantity: number;
  number: string;
  reviewState: string;
  facingDirection: number;
  signId: string;
  hexColor: string;
  messages: string;
  repeatingMessages: string;
  signMessageFields: Array<{
    name: string;
    fieldType: string;
    value: string;
  }>;
  repeatingMessageFields: Array<{
    name: string;
    fieldType: string;
    value: string;
  }>;
  details: string;
  attachments: Array<IAttachment>;
  signType: {
    id: string;
    shortCode: string;
    name: string;
    installPrice: number;
    signPrice: number;
    currency: string;
  };
  location: {
    id: string;
    shortCode: string;
    name: string;
  };
}
export interface IBuilder {
  id: number;
  name: string;
  companyId: string;
  address: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string | undefined | null;
}

export interface IStripeCoupon {
  id: string;
  amount_off: number;
  currency: string;
  duration: string;
  duration_in_months: number;
  metadata: {
    key: string;
    value: string;
  };
  name: string;
  percent_off: number;
  object: string;
  applies_to: Array<string>;
  created: Date;
  currency_options: Array<string>;
  livemode: boolean;
  max_redemptions: number;
  redeem_by: Date;
  times_redeemed: number;
  valid: boolean;
}

export interface IStripeDiscount {
  amount: number;
  discount: {
    id: string;
    coupon: IStripeCoupon;
  };
}

export interface IStripeTiers {
  flat_amount: number;
  flat_amount_decimal: number;
  unit_amount: number;
  unit_amount_decimal: number;
  up_to: number;
}

export interface IStripeTaxes {
  amount: number;
  rate: {
    id: string;
    object: string;
    active: boolean;
    country: string;
    created: Date;
    description: string;
    display_name: string;
    effective_percentage: number;
    inclusive: boolean;
    jurisdication: string;
    livemode: boolean;
    metadata: Array<{
      key: string;
      value: string;
    }>;
    percentage: number;
    state: string;
    tax_type: string;
    taxability_reason: string;
    taxable_amount: number;
  };
}

export interface IStripePrice {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: Date;
  currency: string;
  currency_options: {
    custom_unit_amount: {
      maximum: number;
      minimum: number;
      preset: number;
      tax_behaviour: string;
    };
    tiers: Array<IStripeTiers>;
    unit_amount: number;
    unit_amount_decimal: number;
  };
  custom_unit_amount: {
    maximum: number;
    minimum: number;
    preset: number;
  };
  livemode: boolean;
  lookup_key: string;
  metadata: Array<{
    key: string;
    value: string;
  }>;
  nickname: string;
  product: string;
  recurring: {
    aggregate_usage: string;
    interval: string;
    interval_count: number;
    usage_type: string;
  };
  tax_behaviour: string;
  tiers: Array<IStripeTiers>;
  tiers_mode: string;
  transform_quantity: {
    divide_by: number;
    round: string;
  };
  type: string;
  unit_amount: number;
  unit_amount_decimal: number;
}

export interface IStripeQuote {
  id: string;
  object: string;
  amount_subtotal: number;
  amount_total: number;
  application: string;
  application_fee_amount: number;
  application_fee_percent: number;
  automatic_tax: {
    enabled: boolean;
    status: string;
  };
  collection_method: string;
  computed: {
    recurring: {
      amount_subtotal: number;
      amount_total: number;
      interval: string;
      interval_count: number;
      total_details: {
        amount_discount: number;
        amount_shipping: number;
        amount_tax: number;
        breakdown: {
          discounts: Array<IStripeDiscount>;
          taxes: Array<IStripeTaxes>;
        } | null;
      };
    } | null;
    upfront: {
      amount_subtotal: 0;
      amount_total: 0;
      line_items: {
        object: string;
        data: Array<{
          id: string;
          object: string;
          amount_discount: number;
          amount_subtotal: number;
          amount_tax: number;
          amount_total: number;
          currency: string;
          description: string;
          discounts: Array<IStripeDiscount>;
          price: Array<IStripePrice>;
          quantity: number;
          taxes: Array<IStripeTaxes>;
          has_more: boolean;
          url: string;
        }>;
      } | null;
      total_details: {
        amount_discount: number;
        amount_shipping: number;
        amount_tax: number;
        breakdown: {
          discounts: Array<IStripeDiscount>;
          taxes: Array<IStripeTaxes>;
        } | null;
      };
    };
  };
  created: Date;
  currency: string;
  customer: string;
  default_tax_rates: Array<string>;
  description: string;
  discounts: Array<string>;
  expires_at: Date;
  footer: string;
  from_quote: {
    is_revision: boolean;
    quote: string;
  } | null;
  header: string;
  invoice: string;
  invoice_settings: {
    days_until_due: number;
  };
  livemode: boolean;
  metadata: {
    [key: string]: string;
  };
  number: string;
  on_behalf_of: string;
  status: string;
  status_transitions: {
    accepted_at: Date;
    canceled_at: Date;
    finalized_at: Date;
  };
  subscription: string;
  subscription_data: {
    description: string;
    effective_date: Date;
    trial_period_days: number;
  };
  subscription_schedule: string;
  test_clock: string;
  total_details: {
    amount_discount: number;
    amount_shipping: number;
    amount_tax: number;
    breakdown: {
      discounts: Array<IStripeDiscount>;
      taxes: Array<IStripeTaxes>;
    } | null;
  };
  transfer_data: {
    amount: number;
    amount_percent: number;
    destination: string;
  };
}

export interface IShipTo {
  name: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  showDetails: boolean;
}

export interface ITaxes {
  name: string;
  amount: number;
}

export interface IOrderDetail {
  itemId: number;
  productNumber: string;
  name: string;
  shortCode: string;
  hexColor: string;
  details: string;
  isDetailExpanded: boolean;
  isExpanded: boolean;
  quantity: number;
  price: number;
  imageURL: string;
  quotes: Array<IOrderQuote>;
  signs: Array<IOrderDetailSign>;
}

export interface IOrderQuote {
  builderId: number;
  price: number;
}

export interface IOrderDetailSign {
  id: number;
  signId: string;
  imageURL: string;
  artwork: string | null;
  hexColor: string;
  messages: Array<IMessage>;
  repeatingMessages: Array<IMessage>;
  details: Array<IDetailMessage>;
  attachments: Array<IAttachment>;
  signMessageFields: Array<{
    name: string;
    fieldType: string;
    value: string;
  }>;
  repeatingMessageFields: Array<{
    name: string;
    fieldType: string;
    value: string;
  }>;
  state: {
    approved: string;
    needsReviewed: string;
    rejected: string;
  };
  number: string;
  quantity: number;
  reviewState: string;
  signType: {
    id: string;
    shortCode: string;
    name: string;
    installPrice: number;
    signPrice: number;
    currency: string;
  };
  location: {
    shortCode: string;
    name: string;
  };
}

export interface IAttachment {
  attachmentFileName: string;
  attachment: string;
  user: {
    firstName: string;
    lastName: string;
  };
  datetime: string;
}

export interface IMessage {
  name: string;
  value: string;
}

export interface IDetailMessage {
  name: string;
  value: string;
}

export interface IQuoter {
  id: number;
  email: string;
  inviteSentAt: Date;
}

export interface IOrderSigns {
  edges: Array<{
    node: {
      id: string;
      quantity: string;
      reviewState: string;
      signId: string;
      signType: {
        id: string;
        name: string;
        shortCode: string;
        hexColor: string;
        orderId: number;
        globalOrderIdVal: string;
        folderOrderId: number;
      };
    };
  }>;
}

export interface IOrderItems {
  edges: Array<{
    node: {
      orderLineItemId: string;
      signorderitem: {
        id: number;
        cost: number;
        installCost: number;
        costCurrency: string;
        quantity: number;
        signTemplate: {
          id: string;
          name: string;
          shortCode: string;
          hexColor: string;
          orderId: number;
          globalOrderIdVal: string;
          folder: {
            orderId: number;
          };
        };
        signs: {
          edges: Array<{
            node: {
              id: string;
              artwork: string | null;
              signId: string;
              quantity: number;
              number: string;
              facingDirection: number;
              reviewState: string;
              messages: string;
              repeatingMessages: string;
              signMessageFields: Array<ISignMessageField>;
              repeatingMessageFields: Array<IRepeatingMessageField>;
              details: string;
              attachments: {
                edges: Array<{
                  node: {
                    attachment: string;
                    attachmentFilename: string;
                    user: {
                      firstName: string;
                      lastName: string;
                    };
                    datetime: string;
                  };
                }>;
              };
              location: {
                id: string;
                shortCode: string;
                name: string;
              };
              signType: {
                id: string;
                name: string;
                shortCode: string;
                hexColor: string;
                orderId: number;
                globalOrderIdVal: string;
                folder: {
                  orderId: number;
                };
              };
            };
          }>;
        };
      };
    };
  }>;
}

export interface ISignMessageField {
  attribute: {
    name: string;
    fieldType: string;
  };
  charValue: string;
  textValue: string;
  dateValue: string;
  colorX: {
    name: string;
    color: string;
  };
  icon: {
    name: string;
    imageUrl: string;
  };
}

export interface IRepeatingMessageField {
  field: {
    attribute: {
      name: string;
      fieldType: string;
    };
    charValue: string;
    textValue: string;
    dateValue: string;
    colorX: {
      name: string;
      color: string;
    };
    icon: {
      name: string;
      imageUrl: string;
    };
  };
}

export interface IReviewStateSign {
  id: string;
  quantity: number;
  reviewState: string;
  signId: string;
}

export interface ISignTypeSignQL {
  id: number;
  signId: string;
  number: string;
  quantity: number;
  facingDirection: number;
  reviewState: string;
  artwork: string;
}

export interface ISignTypeQL {
  signType: {
    id: number;
    name: string;
    shortCode: string;
    hexColor: string;
  };
  signs: {
    edges: {
      node: ISignTypeSignQL;
    }[];
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
  };
}
