


























































































































































































































import Vue from "vue";
import toastr from "toastr";

import OrganizationCell from "./OrganizationCell.vue";
import ProjectCell from "./ProjectCell.vue";
import StatesCell from "./StatesCell.vue";
import ProgressCell from "./ProgressCell.vue";
import SignsCell from "./SignsCell.vue";
import StartDateCell from "./StartDateCell.vue";
import EndDateCell from "./EndDateCell.vue";

import {
  IDashboardQL,
  IDashboardQLOrgUser,
  IDashboardRow,
  IDashboardCompany,
  IDashboardCompanyGroup,
  IDashboardProject,
  IDashboardState,
  IDashboardQLProject,
  IDashboardQLState,
  IDashboardProjectDetails,
  IDashboardStateCounts,
  IDashboardProjectUser,
  IDashboardQLOrganization,
  IDashboardProjectUsers,
  IDashboardQLOrgEdges,
} from "../types";

export default Vue.extend({
  components: {
    OrganizationCell,
    ProjectCell,
    StatesCell,
    ProgressCell,
    SignsCell,
    StartDateCell,
    EndDateCell,
  },
  props: {
    projectType: {
      type: String,
      default: "all",
    },
    showProjectFilter: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      companies: [] as Array<IDashboardCompany>,
      organizations: [] as Array<IDashboardRow>,
      sortColumn: "project",
      sortDirection: "asc",
      searchText: "",
      oldSearchText: "",
      filter: "",
      filterOptions: [
        { value: "", label: "No filters" },
        { value: "favorite", label: "Favorites" },
        { value: "active", label: "Active" },
        { value: "overdue", label: "Overdue" },
        { value: "active_overdue", label: "Active/Overdue" },
        { value: "archived", label: "Archived" },
      ],
      tableHeight: 300,
      showProcessing: false,
      hasNextPage: false,
      totalProjects: 0,
      endCursor: "",
      saveFailed: false,
      saveErrorMessage: "",
      abortController: null as AbortController | null,
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.resizeTable);
    window.addEventListener("beforeunload", this.handleUnload);
    this.resizeTable();

    this.getRememberedSettings();

    this.fetchData();

    this.checkForCompanyInvites();
    this.checkForOwnerInvites();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeTable);
    window.removeEventListener("beforeunload", this.handleUnload);
  },
  methods: {
    getRememberedSettings: function () {
      if (typeof Storage !== "undefined") {
        if (localStorage.getItem("project_table_order_column")) {
          this.sortColumn = localStorage.getItem(
            "project_table_order_column"
          ) as string;
        }

        if (localStorage.getItem("project_table_order_direction")) {
          this.sortDirection = localStorage.getItem(
            "project_table_order_direction"
          ) as string;
        }

        // if (localStorage.getItem("project_table_display_start")) {
        //   display_start = Number(
        //     localStorage.getItem("project_table_display_start")
        //   );
        // }

        if (localStorage.getItem("project_table_search_term")) {
          this.searchText = localStorage.getItem(
            "project_table_search_term"
          ) as string;
          this.oldSearchText = this.searchText;
        }

        if (localStorage.getItem("project_table_project_filter")) {
          this.filter = localStorage.getItem(
            "project_table_project_filter"
          ) as string;
        }
      }
    },
    handleUnload: function () {
      localStorage.setItem("project_table_order_column", this.sortColumn);
      localStorage.setItem("project_table_order_direction", this.sortDirection);
      // localStorage.setItem("project_table_display_start", display_start.toString());
      localStorage.setItem("project_table_search_term", this.searchText);
      localStorage.setItem("project_table_project_filter", this.filter);
    },
    userScrolled: function () {
      const el = document.getElementById("dashboard-table-body");
      if (this.hasNextPage) {
        if (el) {
          // the 250 is a buffer so that we don't have to scroll to the very bottom
          if (el.scrollTop + el.clientHeight >= el.scrollHeight - 500) {
            // turn off next page so that this.fetchData doesn't get called multiple times
            // it will reset by fetchData once the data is retrieved
            this.hasNextPage = false;
            this.fetchData();
          }
        }
      }
    },
    updateStartDate: function (stateId: string, newDate: Date) {
      this.organizations.forEach((organization) => {
        organization.project.states.forEach((state) => {
          if (state.id === stateId) {
            state.startDate = new Date(newDate);
          }
        });
      });
    },
    updateEndDate: function (stateId: string, newDate: Date) {
      this.organizations.forEach((organization) => {
        organization.project.states.forEach((state) => {
          if (state.id === stateId) {
            state.endDate = new Date(newDate);
          }
        });
      });
    },
    showError: function (message: string) {
      this.saveFailed = true;
      this.saveErrorMessage = message;
    },
    resizeTable: function () {
      this.tableHeight = window.innerHeight - 190;
    },
    sortByOrganization: function () {
      if (this.sortColumn === "organization") {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "organization";
        this.sortDirection = "asc";
      }
    },
    sortByProject: function () {
      if (this.sortColumn === "project") {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "project";
        this.sortDirection = "asc";
      }
    },
    sortByStartDate: function () {
      if (this.sortColumn === "startDate") {
        // we are already on the start date column so reverse the direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "startDate";
        this.sortDirection = "asc";
      }
    },
    sortByEndDate: function () {
      if (this.sortColumn === "endDate") {
        // we are already on the start date column so reverse the direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "endDate";
        this.sortDirection = "asc";
      }
    },
    reformatData: function (
      orgId: string,
      orgName: string,
      hasOrgOwner: boolean,
      companies: Array<IDashboardQLOrgUser>,
      project: IDashboardQLProject
    ): IDashboardRow {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      companies.forEach(({ node }) => {
        const user = node.user;
        let companyKey = "";
        if (user.company) {
          companyKey = user.company.id;
        } else {
          companyKey = "-1";
        }

        let company = companiesMap.get(companyKey);

        if (!company) {
          if (companyKey === "-1") {
            company = {
              id: "-1",
              name: "None",
              groups: [],
            };
          } else {
            company = {
              id: user.company.id,
              name: user.company.name,
              groups: [],
            };
          }
          companiesMap.set(companyKey, company);
        }

        const group: IDashboardCompanyGroup = {
          id: node.group.id,
          name: node.group.name,
          users: [
            {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            },
          ],
        };

        // Check if group already exists for this company
        const existingGroup = company.groups.find((g) => g.name === group.name);
        if (existingGroup) {
          existingGroup.users.push({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            email: user.email,
          });
        } else {
          company.groups.push(group);
        }
      });

      const resultProject = this.reformatProjectData(project);
      const resultCompanies: IDashboardCompany[] = Array.from(
        companiesMap.values()
      );

      return {
        id: orgId,
        name: orgName,
        hasOwner: hasOrgOwner,
        companies: resultCompanies,
        project: resultProject,
      };
    },
    updateOrgData: function (
      orgId: string,
      newOrg: IDashboardQLOrganization,
      project: IDashboardQLProject
    ) {
      this.organizations.forEach((organization) => {
        if (organization.id === orgId) {
          this.reformatData(
            newOrg.id,
            newOrg.name,
            newOrg.hasOrgOwner,
            newOrg.orgUsers.edges,
            project
          );
        }
      });
    },
    mapOrgCompanies: function (companies: Array<IDashboardQLOrgUser>) {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      companies.forEach(({ node }) => {
        const user = node.user;
        let companyKey = "";
        if (user.company) {
          companyKey = user.company.id;
        } else {
          companyKey = "-1";
        }

        let company = companiesMap.get(companyKey);

        if (!company) {
          if (companyKey === "-1") {
            company = {
              id: "-1",
              name: "None",
              groups: [],
            };
          } else {
            company = {
              id: user.company.id,
              name: user.company.name,
              groups: [],
            };
          }
          companiesMap.set(companyKey, company);
        }

        const group: IDashboardCompanyGroup = {
          id: node.group.id,
          name: node.group.name,
          users: [
            {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            },
          ],
        };

        // Check if group already exists for this company
        const existingGroup = company.groups.find((g) => g.name === group.name);
        if (existingGroup) {
          existingGroup.users.push({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            email: user.email,
          });
        } else {
          company.groups.push(group);
        }
      });

      return Array.from(companiesMap.values());
    },
    mapProjectCompanies: function (companies: Array<IDashboardQLOrgUser>) {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      companies.forEach(({ node }) => {
        const user = node.user;
        let companyKey = "";
        if (user.company) {
          companyKey = user.company.id;
        } else {
          companyKey = "-1";
        }

        let company = companiesMap.get(companyKey);

        if (!company) {
          if (companyKey === "-1") {
            company = {
              id: "-1",
              name: "None",
              groups: [],
            };
          } else {
            company = {
              id: user.company.id,
              name: user.company.name,
              groups: [],
            };
          }
          companiesMap.set(companyKey, company);
        }

        const group: IDashboardCompanyGroup = {
          id: node.group.id,
          name: node.group.name,
          users: [
            {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            },
          ],
        };

        // Check if group already exists for this company
        const existingGroup = company.groups.find((g) => g.name === group.name);
        if (existingGroup) {
          existingGroup.users.push({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            email: user.email,
          });
        } else {
          company.groups.push(group);
        }
      });

      return Array.from(companiesMap.values());
    },
    updateProjectData: function (
      projectId: string,
      projectUsers: IDashboardQLOrgEdges,
      companies: Array<IDashboardQLOrgUser>
    ) {
      // map organization companies
      this.organizations.forEach((organization) => {
        if (organization.project.id === projectId) {
          organization.companies = this.mapOrgCompanies(companies);
          organization.project.companies = this.mapProjectCompanies(
            projectUsers.edges
          );
        }
      });
    },
    reformatProjectData: function (
      project: IDashboardQLProject
    ): IDashboardProject {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      if (project.node.projectUsers) {
        project.node.projectUsers.edges.forEach((projectUser) => {
          const user = projectUser.node.user;
          let companyKey = "";
          if (user.company) {
            companyKey = user.company.id;
          } else {
            companyKey = "-1";
          }

          let company = companiesMap.get(companyKey);

          if (!company) {
            if (companyKey === "-1") {
              company = {
                id: "-1",
                name: "None",
                groups: [],
              };
            } else {
              company = {
                id: user.company.id,
                name: user.company.name,
                groups: [],
              };
            }
            companiesMap.set(companyKey, company);
          }

          const group: IDashboardCompanyGroup = {
            id: projectUser.node.group.id,
            name: projectUser.node.group.name,
            users: [
              {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                image: user.image,
                email: user.email,
              },
            ],
          };

          // Check if group already exists for this company
          const existingGroup = company.groups.find(
            (g) => g.name === group.name
          );
          if (existingGroup) {
            existingGroup.users.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            });
          } else {
            company.groups.push(group);
          }
        });
      }

      const companies: IDashboardCompany[] = Array.from(companiesMap.values());

      let states = [] as Array<IDashboardState>;
      project.node.states.edges.forEach((state) => {
        states.push(this.reformatStateData(state));
      });

      return {
        id: project.node.id,
        name: project.node.name,
        type: project.node.phaseType.substring(2),
        canEdit: project.node.canEdit,
        archived: project.node.archived,
        favoriteUsers: project.node.favoriteUsers.edges.map((user) => {
          return { userId: user.node.userId };
        }),
        companies: companies,
        states: states,
      };
    },
    updateStateData: function (state: IDashboardStateCounts) {
      this.organizations.forEach((organization) => {
        organization.project.states.forEach((orgState) => {
          if (orgState.id === state.node.id) {
            const companiesMap: Map<string, IDashboardCompany> = new Map();

            if (state.node.stateUsers) {
              state.node.stateUsers.edges.forEach((stateUser) => {
                const user = stateUser.node.user;
                let companyKey = "";
                if (user.company) {
                  companyKey = user.company.id;
                } else {
                  companyKey = "-1";
                }

                let company = companiesMap.get(companyKey);

                if (!company) {
                  if (companyKey === "-1") {
                    company = {
                      id: "-1",
                      name: "None",
                      groups: [],
                    };
                  } else {
                    company = {
                      id: user.company.id,
                      name: user.company.name,
                      groups: [],
                    };
                  }
                  companiesMap.set(companyKey, company);
                }

                const group: IDashboardCompanyGroup = {
                  id: stateUser.node.group.id,
                  name: stateUser.node.group.name,
                  users: [
                    {
                      id: user.id,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      image: user.image,
                      email: user.email,
                    },
                  ],
                };

                // Check if group already exists for this company
                const existingGroup = company.groups.find(
                  (g) => g.name === group.name
                );
                if (existingGroup) {
                  existingGroup.users.push({
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    image: user.image,
                    email: user.email,
                  });
                } else {
                  company.groups.push(group);
                }
              });
            }

            orgState.companies = Array.from(companiesMap.values());

            orgState.signQuantity = state.node.signQuantity;
            orgState.acceptedSigns = state.node.acceptedSignQuantity;
            orgState.rejectedSigns = state.node.rejectedSignQuantity;
            orgState.needsReviewedSigns = state.node.needsReviewSignQuantity;
            orgState.firstSignNeedingReview = state.node.firstSignNeedingReview;
            orgState.dashboardUrlParams = state.node.dashboardUrlParams;
          }
        });
      });
    },
    reformatStateData: function (state: IDashboardQLState): IDashboardState {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      if (state.node.stateUsers) {
        state.node.stateUsers.edges.forEach((stateUser) => {
          const user = stateUser.node.user;
          let companyKey = "";
          if (user.company) {
            companyKey = user.company.id;
          } else {
            companyKey = "-1";
          }

          let company = companiesMap.get(companyKey);

          if (!company) {
            if (companyKey === "-1") {
              company = {
                id: "-1",
                name: "None",
                groups: [],
              };
            } else {
              company = {
                id: user.company.id,
                name: user.company.name,
                groups: [],
              };
            }
            companiesMap.set(companyKey, company);
          }

          const group: IDashboardCompanyGroup = {
            id: stateUser.node.group.id,
            name: stateUser.node.group.name,
            users: [
              {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                image: user.image,
                email: user.email,
              },
            ],
          };

          // Check if group already exists for this company
          const existingGroup = company.groups.find(
            (g) => g.name === group.name
          );
          if (existingGroup) {
            existingGroup.users.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            });
          } else {
            company.groups.push(group);
          }
        });
      }

      const companies: IDashboardCompany[] = Array.from(companiesMap.values());

      return {
        id: state.node.id,
        name: state.node.name,
        type: state.node.stateType.substring(2),
        archived: state.node.archived,
        startDate: state.node.startDate ? new Date(state.node.startDate) : null,
        endDate: state.node.endDate ? new Date(state.node.endDate) : null,
        canEdit: state.node.canEdit,
        workflow: state.node.workflow.id,
        hexColor: state.node.hexColor,
        approved: state.node.approved,
        rejected: state.node.rejected,
        needsReviewed: state.node.needsReviewed,
        orderId: state.node.orderId,
        signQuantity: null,
        acceptedSigns: null,
        rejectedSigns: null,
        needsReviewedSigns: null,
        firstSignNeedingReview: 0,
        dashboardUrlParams: "",
        companies: companies,
      };
    },
    buildOrderByString: function (): string {
      let result = "";
      if (this.sortDirection === "desc") {
        result = "-";
      }

      switch (this.sortColumn.toLowerCase()) {
        case "project":
          result += "name";
          break;
        case "organization":
          result += "organization_name";
          break;
      }
      if (result === "" || result === "-") {
        this.sortColumn = "organization";
        result = "organization_name";
      }
      return result;
    },
    freshFetchData: function () {
      this.organizations = [];
      this.hasNextPage = false;
      this.endCursor = "";
      this.oldSearchText = this.searchText;
      const el = this.$refs.dashboardTable as HTMLElement;
      if (el) {
        el.scrollTop = 0;
      }
      // then fetch the data
      this.fetchData();
    },
    checkForCompanyInvites: function () {
      const query = JSON.stringify({
        query: `query getCompanyInvites {
          pendingInvites (email_Iexact: "${this.$store.state.session.email}") {
            edges {
              node {
                id: contentObjectId
                email
                acceptedOn
                rejectedOn
                expiresOn
                company {
                  id: contentObjectId
                  name
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.data.pendingInvites.edges.length > 0) {
            const hasInvite =
              result.data.pendingInvites.edges.filter((node: any) => {
                return (
                  node.node.acceptedOn === null &&
                  node.node.rejectedOn === null &&
                  (node.node.expiresOn === null ||
                    new Date(node.node.expiresOn) > new Date())
                );
              }).length > 0;
            if (hasInvite) {
              const urlInvites = `<a type="button" class="btn btn-default" id="invites_button" href="/company/pending_invites/${this.$store.state.session.profileId}/">Here.</a>`;
              toastr.info(
                "Please review your pending company invites." + urlInvites,
                "Pending Company Invites",
                {
                  timeOut: 0,
                  extendedTimeOut: 0,
                }
              );
            }
          }
        });
    },
    checkForOwnerInvites: function () {
      const query = JSON.stringify({
        query: `query getOwnerInvites {
                  organizationPendingInvites (prospectOwnerEmail_Iexact: "${this.$store.state.session.email}") {
                  edges {
                    node {
                      prospectOwnerEmail
                    }
                  }
                }
              }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          const inviteCount =
            result.data.organizationPendingInvites.edges.length;
          if (inviteCount > 0) {
            const urlInvites = `<a type="button" class="btn btn-default" id="invites_button" href="/organization/pending_invites/${this.$store.state.session.profileId}/">Here.</a>`;
            toastr.info(
              "Please review your pending ownership invites." + urlInvites,
              "Pending Owner Invites",
              {
                timeOut: 0,
                extendedTimeOut: 0,
              }
            );
          }
        });
    },
    fetchData: async function () {
      // Create a new AbortController for this fetch
      const currentController = new AbortController();
      this.abortController = currentController;

      this.showProcessing = true;

      const projectSelector =
        this.projectType === "all"
          ? ", projectType_In: [1,2]"
          : `, projectType_In: ${this.projectType}`;
      const orderBy = this.buildOrderByString();
      const nameContains =
        this.searchText !== ""
          ? `, orgOrProject_Name_Contains: "${this.searchText}"`
          : "";
      const query = JSON.stringify({
        query: `query getProjects {
          projects (first: 10 ${projectSelector}, after: "${this.endCursor}", orderBy: "${orderBy}" ${nameContains}, projectFilter: "${this.filter}") {
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
            edges {
              node {
                id: contentObjectId
                name
                phaseType
                canEdit
                archived
                organization:project {
                  id:contentObjectId
                  name
                  hasOrgOwner
                }
                favoriteUsers {
                  edges {
                    node {
                      userId
                    }
                  }
                }
                states {
                  edges {
                    node {
                      id: contentObjectId
                      name
                      startDate
                      endDate
                      stateType
                      canEdit
                      workflow {
                        id: contentObjectId
                      }
                      hexColor
                      approved
                      rejected
                      needsReviewed
                      orderId
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      try {
        const response = await fetch("/graphql/", {
          method: "POST",
          body: query,
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
          signal: currentController.signal, // Attach signal for cancellation
        });

        const result: IDashboardQL = await response.json();

        // If another fetch was started, abandon processing this result
        if (this.abortController !== currentController) {
          return;
        }

        // Process response data...
        this.hasNextPage = result.data.projects.pageInfo.hasNextPage;
        this.endCursor = result.data.projects.pageInfo.endCursor;
        this.totalProjects = result.data.projects.totalCount;

        for (const project of result.data.projects.edges) {
          let newOrg = {
            id: project.node.organization.id,
            name: project.node.organization.name,
            hasOwner: project.node.organization.hasOrgOwner,
            companies: [],
            project: {} as IDashboardProject,
          } as IDashboardRow;

          newOrg = this.reformatData(
            project.node.organization.id,
            project.node.organization.name,
            project.node.organization.hasOrgOwner,
            project.node.organization.orgUsers
              ? project.node.organization.orgUsers.edges
              : [],
            project
          );

          if (project.node.phaseType === "A_0") {
            if (this.$store.state.session.isDesigner) {
              this.organizations.push(newOrg);
            }
          } else {
            this.organizations.push(newOrg);
          }

          this.fetchProjectDetails(newOrg.project.id).then((projectDetails) => {
            if (this.abortController !== currentController) return; // Ensure we don't process stale data

            this.updateOrgData(newOrg.id, projectDetails.organization, project);
            this.updateProjectData(
              newOrg.project.id,
              { edges: projectDetails.projectUsers },
              projectDetails.organization.orgUsers.edges
            );
            projectDetails.states.edges.forEach((edge) => {
              this.updateStateData(edge);
            });
          });
        }

        this.$nextTick(() => {
          const el = document.getElementById("dashboard-table-body");
          if (el) {
            el.addEventListener("scroll", this.userScrolled);
          }
        });

        this.showProcessing = false;
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch request was aborted");
          return;
        }
        console.error("Error fetching data:", error);
      }
    },
    fetchProjectDetails: async function (
      projectId: string
    ): Promise<IDashboardProjectDetails> {
      const query = `
        query get_project_details {
          project(id: ${projectId}) {
            id: contentObjectId
            organization:project {
              id:contentObjectId
              name
              hasOrgOwner
              orgUsers:groupUsers (groupNames:["admin"]) {
                edges {
                  node {
                    group{
                      id: contentObjectId
                      name
                    }
                    user {
                      id: contentObjectId
                      firstName
                      lastName
                      image
                      email
                      company {
                        id: contentObjectId
                        name
                      }
                    }
                  }
                }
              }

            }
            projectUsers: groupUsers(groupNames: ["member"]) {
              edges {
                node {
                  group {
                    id: contentObjectId
                    name
                  }
                  user {
                    id: contentObjectId
                    firstName
                    lastName
                    image
                    email
                    company {
                      id: contentObjectId
                      name
                    }
                  }
                }
              }
            }
            states {
              edges {
                node {
                  id: contentObjectId
                  signQuantity
                  acceptedSignQuantity
                  rejectedSignQuantity
                  needsReviewSignQuantity
                  firstSignNeedingReview
                  dashboardUrlParams
                  stateUsers: groupUsers(groupNames: ["assigned"]) {
                    edges {
                      node {
                        group{
                          id: contentObjectId
                          name
                        }
                        user {
                          id: contentObjectId
                          firstName
                          lastName
                          image
                          email
                          company {
                            id: contentObjectId
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `;

      const response = await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
        }),
      });

      const { data, errors } = await response.json();

      data.project.projectUsers = data.project.projectUsers.edges;
      if (errors) {
        console.error("GraphQL Errors:", errors);
      }

      return data.project;
    },
  },
  computed: {
    filteredProjects: function (): Array<IDashboardRow> {
      return this.organizations;
    },
    visibleOrganizations: function (): Array<IDashboardRow> {
      return this.filteredProjects;
      // let filteredOrgs = [] as Array<IDashboardRow>;
      // if (this.filter === "archived") {
      //   filteredOrgs = this.filteredProjects.filter((organization) => {
      //     return organization.project.archived;
      //   });
      // } else {
      //   filteredOrgs = this.filteredProjects.filter((organization) => {
      //     return !organization.project.archived;
      //   });
      // }

      // if (this.filter === "favorite") {
      //   filteredOrgs = this.filteredProjects.filter((organization) => {
      //     return organization.project.favoriteUsers.some((user) => {
      //       return user.userId === this.$store.state.session.userId;
      //     });
      //   });
      // }

      // const isActive = function (state: IDashboardState): boolean {
      //   const today = new Date();
      //   if (state.startDate) {
      //     return (
      //       state.startDate <= today &&
      //       (!state.endDate || state.endDate >= today)
      //     );
      //   } else {
      //     return false;
      //   }
      // };

      // const isOverDue = function (state: IDashboardState): boolean {
      //   return state.endDate ? state.endDate < new Date() : false;
      // };

      // if (this.filter === "active") {
      //   filteredOrgs = this.filteredProjects.filter((organization) => {
      //     return organization.project.states.some((state) => {
      //       return isActive(state);
      //     });
      //   });
      // }

      // if (this.filter === "overdue") {
      //   filteredOrgs = this.filteredProjects.filter((organization) => {
      //     return organization.project.states.some((state) => {
      //       return isOverDue(state);
      //     });
      //   });
      // }

      // if (this.filter === "activeoverdue") {
      //   filteredOrgs = this.filteredProjects.filter((organization) => {
      //     return organization.project.states.some((state) => {
      //       return isActive(state) || isOverDue(state);
      //     });
      //   });
      // }

      // return filteredOrgs;
    },
    sortedOrganizations: function (): Array<IDashboardRow> {
      // make sure that localeCompare is used here because there is a difference between
      // Django and typescript on sorting.
      // Django uses
      //    ASCII/Unicode ordering.
      //    Special characters come before letters and numbers.
      //    Sorting is case-sensitive (A < Z < a < z).
      // TypeScript uses
      //    Unicode code points.
      //    Special characters are sorted based on Unicode values.
      //    may sort case-insensitively depending on locale settings
      // Using localeCompare will make sure that the sorting is consistent
      const filteredOrgs = this.visibleOrganizations;
      return filteredOrgs;
      // return filteredOrgs.slice().sort((a, b) => {
      //   let comparison = 0;

      //   if (this.sortColumn === "project") {
      //     comparison = a.project.name.localeCompare(b.project.name, "en", {
      //       sensitivity: "base",
      //     });
      //   } else if (this.sortColumn === "organization") {
      //     comparison = a.name.localeCompare(b.name, "en", {
      //       sensitivity: "base",
      //     });
      //   }

      //   return this.sortDirection === "asc" ? comparison : -comparison;
      // });
    },
  },
  watch: {
    // searchText: function (newValue, oldValue) {
    //   this.oldSearchText = oldValue;
    // },
    projectType: function () {
      //clear the eixsting organizations and reset pagination info
      // then fetch the data
      this.freshFetchData();
    },
    sortDirection: function () {
      this.freshFetchData();
    },
    sortColumn: function () {
      // sort criteria has changed so load new data
      this.freshFetchData();
    },
    filter: function () {
      // if user selects a different filter, reload the data.
      this.freshFetchData();
    },
  },
});
