import { RouteConfig } from "vue-router";

import PathNotFound_FullPage from "@/mounters/components/PathNotFound_FullPage.vue";
import PathNotFound_MapView from "@/mounters/components/PathNotFound_MapView.vue";
import ProjectKanban from "@/features/BuilderKanban/views/ProjectKanban.vue";
import ProjectColumns from "@/features/BuilderKanban/views/ProjectColumns.vue";
import Marketplace from "@/features/SignDesigner/components/Marketplace.vue";
import StateForm from "@/features/SmartStates/SmartStates.vue";
import OrderForm from "@/features/Orders/App.vue";
import OrderConfirmation from "@/features/Orders/views/OrderConfirmation.vue";
import Builder from "@/features/Builder/App.vue";
import BuilderForm from "@/features/Builder/views/Builder.vue";
import SetupBuilder from "@/features/Builder/views/SetupBuilder.vue";
import BuilderOrders from "@/features/Builder/views/Orders.vue";
import BuilderBuildProjects from "@/features/Builder/views/BuildProjects.vue";
import BuilderInstallProjects from "@/features/Builder/views/InstallProjects.vue";
import BuilderKanban from "@/features/Builder/views/ProjectKanban.vue";
import BuilderKanbanColumns from "@/features/Builder/views/ProjectColumns.vue";
import BuilderOrder from "@/features/Builder/views/Order.vue";

import Design from "@/features/Design/App.vue";

import Manage from "@/features/Manage/App.vue";
import ManageOrgs from "@/features/Manage/views/Organizations.vue";

import CompanySettings from "@/features/CompanySettings/CompanySettings.vue";
import OnlineBilling from "@/features/CompanySettings/views/OnlineBillingSetup.vue";
import Paywall from "@/features/CompanySettings/views/Paywall.vue";
import Billing from "@/features/CompanySettings/views/Billing.vue";
import Members from "@/features/CompanySettings/views/Members.vue";
import Company from "@/features/CompanySettings/views/Company.vue";
import PricePlanList from "@/features/CompanySettings/views/PricePlanList.vue";
import PricePlanAdd from "@/features/CompanySettings/views/PricePlanAdd.vue";
import PricePlanEdit from "@/features/CompanySettings/views/PricePlanEdit.vue";
import Companies from "@/features/CompanySettings/views/Companies.vue";
import SignDesigner from "@/features/SignDesigner/SignDesigner.vue";

/**
 * Use this as a template to import a sub-path of paths from another file.
 *
 * Example:
 * ```ts
 * import * as myFeature from "@/features/MyFeature/routes";
 * {
 *   ...moduleRoute,
 *   path: "/my-feature/",
 *   children: myFeature.fullPageRoutes,
 * },
 * ```
 */
export const moduleRoute: RouteConfig = {
  path: "",
  component: { template: "<router-view></router-view>" },
  children: [],
};

/**
 * Routes for full page Vue components
 *
 * @param meta.requiresAuthentication - false disables the authentication check, true (default) does not
 *
 * # urls.py
 * Each URL requires a server-side entry in a urls.py (ex. `project/urls.py`) like:
 *
 * ```python
 * # mark /foo/ as a full page Vue route
 * path('^foo/', full_page_vue, name='foo'),
 *
 * # OR, mark /foo/ and all sub-paths as full page Vue routes
 * path('foo<path:path>', full_page_vue, name='foo'),
 * ```
 *
 * Note that `login_required` is optional
 *
 * # Component
 * Target components should implement the FullPageLayout component as their base component. See FullPageLayout's documentation, but it has features such as allowing a sidebar, setting the window title, etc.
 *
 * ```vue-html
 * <template>
 *   <FullPageLayout title="Foo">
 *     <h1>Foo</h1>
 *     ...
 *   </FullPageLayout>
 * </template>
 * ```
 */
export const fullPageRoutes: RouteConfig[] = [
  {
    name: "marketplace",
    path: "/marketplace/",
    component: Marketplace,
  },
  {
    path: "/design/",
    name: "design",
    component: Design,
  },
  {
    path: "/build/",
    name: "builder",
    component: Builder,
    children: [
      {
        path: "/builder/:id/edit",
        component: BuilderForm,
      },
      {
        path: "/builder/create",
        component: BuilderForm,
      },
      {
        path: "/builder/:id/setup",
        component: SetupBuilder,
      },
      {
        path: "/builder/orders",
        component: BuilderOrders,
      },
      {
        path: "/builder/buildprojects",
        component: BuilderBuildProjects,
      },
      {
        path: "/builder/installprojects",
        component: BuilderInstallProjects,
      },
      {
        path: "/builder/kanban/:id",
        component: BuilderKanban,
      },
      {
        path: "/builder/:id/kanban/columns",
        component: BuilderKanbanColumns,
      },
      {
        path: "/builder/order/:id",
        component: BuilderOrder,
      },
    ],
  },

  {
    path: "/manage/",
    name: "manage",
    component: Manage,
    children: [
      {
        path: "/manage/orders",
        component: BuilderOrders,
      },
      {
        path: "/manage/organizations",
        component: ManageOrgs,
      },
    ],
  },

  {
    path: "/company/:id",
    component: CompanySettings,
    children: [
      {
        path: "/company/:id",
        component: Company,
      },
      {
        path: "/company/:id/paywall",
        component: Paywall,
      },
      {
        path: "/company/:id/billing",
        component: Billing,
      },
      {
        path: "/company/:id/members",
        component: Members,
      },
      {
        path: "/company/:id/companies",
        component: Companies,
      },
      {
        path: "/company/:id/signupforonline",
        component: OnlineBilling,
      },
      {
        path: "/company/:id/priceplans/list",
        component: PricePlanList,
      },
      {
        path: "/company/:id/priceplans/add",
        component: PricePlanAdd,
      },
      {
        path: "/company/:id/priceplans/:pricePlanId/edit",
        component: PricePlanEdit,
      },
    ],
  },
  // path for sign designer
  {
    name: "designer",
    path: "/sign_template/:id/designer/",
    component: SignDesigner,
  },
  // 404 - Not found (should appear last)
  {
    path: "/:pathMatch(.*)*",
    component: PathNotFound_FullPage,
    meta: { requiresAuthentication: false },
  },
];

/**
 * Routes for Vue components on the map view
 *
 * # urls.py
 * Each URL requires a server-side entry in a urls.py file. Use the urls.py file in the app for the object you want represented on the map, and use a corresponding {...}Detail object from the Vue app.
 *
 * ```python
 * # mark organization/<int>/foo/ as a Vue route on the map view in `sign_project/urls.py`
 * path('<int:pk>/foo/', ProjectDetailVue.as_view(), name="foo"),
 *
 * # OR, mark /foo/ and all sub-paths as Vue routes
 * path('<int:pk>/foo<path:path>', ProjectDetailVue.as_view(), name="foo"),
 * ```
 *
 * # Component
 * Target components should implement the MapViewLayout component as their base component: See MapViewLayout's documentation, but it has features such as allowing fullscreen, hiding the sign bar, etc.
 *
 * ```vue-html
 * <template>
 *   <MapViewLayout size="small" signbar="small" :loading="loading">
 *     <h1>Foo</h1>
 *     ...
 *   </MapViewLayout>
 * </template>
 * ```
 */
export const mapViewRoutes: RouteConfig[] = [
  // {
  //   name: "Foo",
  //   path: "/organization/:id/foo/",
  //   component: Foo,
  // },
  {
    path: "/state/create/",
    name: "StateCreate",
    component: StateForm,
  },
  {
    path: "/state/:id/edit/",
    name: "StateEdit",
    component: StateForm,
  },
  {
    path: "/order/:id/edit/",
    name: "OrderEdit",
    component: OrderForm,
  },
  {
    path: "/order/:id/confirmation/",
    name: "OrderConfirm",
    component: OrderConfirmation,
  },
  {
    path: "/project/:id/kanban/columns",
    name: "ProjectColumns",
    component: ProjectColumns,
  },
  {
    path: "/project/:id/kanban",
    name: "ProjectKanban",
    component: ProjectKanban,
  },
  // 404 - Not found (should appear last)
  { path: "/:pathMatch(.*)*", component: PathNotFound_MapView },
];
