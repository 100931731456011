





























import Vue from "vue";
import { PropType } from "vue";

import { IDashboardState } from "../types";

export default Vue.extend({
  props: {
    state: Object as PropType<IDashboardState>,
  },
  computed: {
    acceptedPercentage: function (): number | null {
      const reviewCount = this.state.needsReviewedSigns;
      const approvedCount = this.state.acceptedSigns;
      const rejectedCount = this.state.rejectedSigns;

      let total = 0;
      if (
        reviewCount != null &&
        approvedCount != null &&
        rejectedCount != null
      ) {
        total = reviewCount + approvedCount + rejectedCount;

        if (total === 0) {
          return 0;
        } else {
          return (100 * approvedCount) / total;
        }
      } else {
        return null;
      }
    },
    rejectedPercentage: function (): number {
      const reviewCount = this.state.needsReviewedSigns;
      const approvedCount = this.state.acceptedSigns;
      const rejectedCount = this.state.rejectedSigns;

      if (reviewCount && approvedCount && rejectedCount) {
        const total = reviewCount + approvedCount + rejectedCount;

        if (total === 0) {
          return 0;
        } else {
          return (
            (100 * rejectedCount) / total +
            (this.acceptedPercentage ? this.acceptedPercentage : 0)
          );
        }
      } else {
        return 0;
      }
    },
  },
});
