








































































































import Vue from "vue";
import { IOrderListOrder, IOrderListOrderQL } from "../types";
import { ORDER_STATUS } from "@/features/Orders/types";

type IStatusType = {
  [key: string]: string;
};

export default Vue.extend({
  data: function () {
    return {
      orders: [] as Array<IOrderListOrder>,
      sortColumn: "status",
      sortDirection: "up",
      searchText: "",
      builderId: "",
      tableHeight: 400,
    };
  },
  mounted: function () {
    this.fetchOrders();
    this.setTableHeight();
    window.addEventListener("resize", this.setTableHeight);
  },
  methods: {
    setTableHeight: function () {
      this.tableHeight = window.innerHeight - 125;
    },
    sortOrdersByStatus: function () {
      this.sortColumn = "status";
      let direction = 0;
      if (this.sortDirection === "up") {
        this.sortDirection = "down";
        direction = 1;
      } else {
        this.sortDirection = "up";
        direction = -1;
      }
      this.orders.sort((orderA, orderB) => {
        // graphQL sends the status back as A_2 we strip the A_ to get the number
        return (
          (Number(orderA.status.substring(2)) -
            Number(orderB.status.substring(2))) *
          direction
        );
      });
    },
    sortOrdersByDueDate: function () {
      this.sortColumn = "duedate";
      let direction = 0;
      if (this.sortDirection === "up") {
        this.sortDirection = "down";
        direction = -1;
      } else {
        this.sortDirection = "up";
        direction = 1;
      }

      this.orders.sort((a, b) => {
        // Handle null values
        if (a.dueDate === null && b.dueDate === null) {
          return 0;
        }
        if (a.dueDate === null) {
          return direction;
        }
        if (b.dueDate === null) {
          return -direction;
        }
        // Compare dates if both are not null
        return (
          (new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()) *
          direction
        );
      });
    },
    sortOrdersByFrom: function () {
      this.sortColumn = "from";
      if (this.sortDirection === "up") {
        this.sortDirection = "down";
        this.orders.sort((orderA, orderB) => {
          // graphQL sends the status back as A_2 we strip the A_ to get the number
          return orderA.manager.email.localeCompare(orderB.manager.email);
        });
      } else {
        this.sortDirection = "up";
        this.orders.sort((orderA, orderB) => {
          // graphQL sends the status back as A_2 we strip the A_ to get the number
          return orderB.manager.email.localeCompare(orderA.manager.email);
        });
      }
    },
    sortOrdersByName: function () {
      this.sortColumn = "name";
      if (this.sortDirection === "up") {
        this.sortDirection = "down";
        this.orders.sort((orderA, orderB) => {
          // graphQL sends the status back as A_2 we strip the A_ to get the number
          return orderA.name.localeCompare(orderB.name);
        });
      } else {
        this.sortDirection = "up";
        this.orders.sort((orderA, orderB) => {
          // graphQL sends the status back as A_2 we strip the A_ to get the number
          return orderB.name.localeCompare(orderA.name);
        });
      }
    },
    fetchOrders: function () {
      const query = JSON.stringify({
        query: `query {
          orders {
            edges {
              node {
                id:contentObjectId
                name
                dueDate
                manager {
                  id: contentObjectId
                  email
                }
                status
                builders {
                  edges {
                    node {
                      id: contentObjectId
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors);
          } else {
            result.data.orders.edges.forEach(
              (edge: { node: IOrderListOrderQL }) => {
                const newOrder = {
                  id: edge.node.id,
                  name: edge.node.name,
                  dueDate: edge.node.dueDate,
                  status: edge.node.status,
                  manager: edge.node.manager,
                  builderId:
                    edge.node.builders.edges.length > 0
                      ? edge.node.builders.edges[0].node.id
                      : "",
                } as IOrderListOrder;

                this.builderId = newOrder.builderId;

                this.orders.push(newOrder);
              }
            );
            this.sortOrdersByStatus();
          }
        });
    },
    /**
     * edit order
     * navigate to the order page for the id that is passed in
     */
    editOrder: function (order: IOrderListOrder) {
      if (Number(order.status.substring(2)) > Number(ORDER_STATUS.IN_REVIEW)) {
        //show the html of the invoice
        window.location.href = `/order/${order.id}/client_invoice_pdf/`;
      } else {
        window.location.href = "/builder/order/" + order.id + "/";
      }
    },
    /**
     * order status name
     * return the name of the order status of a given status id
     */
    orderStatusName: function (value: string, data: IStatusType): string {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          // because the status comes from graphQL as A_10 we need to strip the A_ to get the statuses number
          if (data[key] === value.substring(2)) {
            return this.formattedStatusName(key);
          }
        }
      }
      return ""; // Return empty string if value is not found
    },
    /**
     * return the status name formatted in a more human friendly format
     */
    formattedStatusName: function (statusString: string) {
      //first replace the underscores with spaces
      let formattedString = statusString.replace(/_/g, " ");

      //then change the text be capitalized not uppercase
      formattedString = formattedString
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return formattedString;
    },
  },
  computed: {
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
    filteredOrders: function (): Array<IOrderListOrder> {
      if (this.searchText === "") {
        return this.orders;
      } else {
        return this.orders.filter((order) => {
          return (
            order.name.includes(this.searchText) ||
            order.manager.email.includes(this.searchText) ||
            this.orderStatusName(order.status, ORDER_STATUS)
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
        });
      }
    },
  },
});
