var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"col-lg-12",staticStyle:{"display":"flex"}},[_c('h3',{staticStyle:{"flex-grow":"1"}},[_vm._v("Orders")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}),_vm._m(0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 table-container",style:({height: _vm.tableHeight + 'px'})},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{on:{"click":_vm.sortOrdersByName}},[_vm._v(" Name "),_c('i',{class:_vm.sortColumn === 'name'
                    ? _vm.sortDirection === 'up'
                      ? 'fa-duotone fa-sort-down'
                      : 'fa-duotone fa-sort-up'
                    : 'fa-thin fa-sort'})]),_c('th',{on:{"click":_vm.sortOrdersByFrom}},[_vm._v(" From "),_c('i',{class:_vm.sortColumn === 'from'
                    ? _vm.sortDirection === 'up'
                      ? 'fa-duotone fa-sort-down'
                      : 'fa-duotone fa-sort-up'
                    : 'fa-thin fa-sort'})]),_c('th',{on:{"click":_vm.sortOrdersByDueDate}},[_vm._v(" Due Date "),_c('i',{class:_vm.sortColumn === 'duedate'
                    ? _vm.sortDirection === 'up'
                      ? 'fa-duotone fa-sort-up'
                      : 'fa-duotone fa-sort-down'
                    : 'fa-thin fa-sort'})]),_c('th',{on:{"click":_vm.sortOrdersByStatus}},[_vm._v(" Status "),_c('i',{class:_vm.sortColumn === 'status'
                    ? _vm.sortDirection === 'up'
                      ? 'fa-duotone fa-sort-down'
                      : 'fa-duotone fa-sort-up'
                    : 'fa-thin fa-sort'})])])]),_c('tbody',_vm._l((_vm.filteredOrders),function(order){return _c('tr',{key:order.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editOrder(order)}}},[_c('td',[_c('span',{staticClass:"jstree-pill bg-build",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(order.name)+" ")])]),_c('td',[_vm._v(_vm._s(order.manager.email))]),_c('td',[_vm._v(" "+_vm._s(order.dueDate ? new Date(order.dueDate).toISOString().substring(0, 10) : "")+" ")]),_c('td',[_vm._v(_vm._s(_vm.orderStatusName(order.status, _vm.ORDER_STATUS)))])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-search"})])])}]

export { render, staticRenderFns }