






































































































































































































import Vue from "vue";
import { PropType } from "vue";
import { IDashboardState, IDashboardCompany } from "../types";

import StateArchiveWidget from "./StateArchiveWidget.vue";
import UserPopupWidget from "./UserPopupWidget.vue";

import { ORDER_STATUS } from "@/features/Orders/types";
import { STATE_TYPE } from "@/features/SmartStates/utilities";

export default Vue.extend({
  components: { StateArchiveWidget, UserPopupWidget },
  props: {
    states: {
      type: Array as PropType<IDashboardState[]>,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    userHasChangePerms: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      hoverStateArchive: false,
    };
  },
  methods: {
    getStateParams: function (state: IDashboardState) {
      return state.dashboardUrlParams;
      // const state_parameter = `s=${state.id}`;
      // const location_parameters = `z={pk}` for pk in self.project.zones.all().values_list('pk', flat=True)]
      // const types_parameters = `t={pk}` for pk in self.project.sign_templates.all().values_list('pk', flat=True)]
      // return url_params = '&'.join(state_parameter + location_parameters + types_parameters)
    },
    archiveState: function (e: Event, state: IDashboardState) {
      e.preventDefault();
      //first update the Vue data
      // toggle the archive flag
      state.archived = !state.archived;
      //update the backend
      fetch("/state/" + state.id + "/archive/", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/text",
        },
      }).then((data) => data);
    },
    firstSignInReview: function (state: IDashboardState): number {
      return state.firstSignNeedingReview;
    },
    nameStyle: function (state: IDashboardState): string {
      if (state.archived) {
        return "";
      } else if (state.endDate && new Date() > state.endDate) {
        return "font-weight: bold";
      } else if (state.startDate && new Date() >= state.startDate) {
        return "font-weight: bold";
      } else if (state.startDate) {
        return "";
      } else {
        return "";
      }
    },
    viewers: function (companies: Array<IDashboardCompany>) {
      return companies.filter((company) =>
        company.groups.some((group) => group.name.toLowerCase() === "assigned")
      );
    },
    hasViewers: function (companies: Array<IDashboardCompany>) {
      return companies.some((company) =>
        company.groups.some((group) => group.name.toLowerCase() === "assigned")
      );
    },
  },
  computed: {
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
    STATE_TYPE: function () {
      return STATE_TYPE;
    },
  },
});
