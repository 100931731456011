






























































import Vue from "vue";
import { PropType } from "vue";
import { IDashboardRow, IDashboardCompany } from "../types";

import UserPopupWidget from "./UserPopupWidget.vue";

export default Vue.extend({
  components: { UserPopupWidget },
  props: {
    organization: {
      type: Object as PropType<IDashboardRow>,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    adminsAndViewers: function (companies: Array<IDashboardCompany>) {
      return companies.filter((company) =>
        company.groups.some(
          (group) =>
            group.name.toLowerCase() === "admin" ||
            group.name.toLowerCase() === "assigned"
        )
      );
    },
  },
  computed: {
    isManaged: function (): boolean {
      return this.organization.hasOwner;
    },
    isBuilder: function (): boolean {
      if (
        ["1", "2"].includes(this.organization.project.type) &&
        this.hasChangePermProject
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasChangePermProject: function (): boolean {
      return this.organization.project.canEdit;
    },
    organizationMenu: function (): string {
      let result = "";

      if (this.hasChangePermProject)
        if (this.isBuilder) {
          // don't allow build to edit or add users from dashboard
          result = "";
        } else {
          const editURL = "/organization/" + this.organization.id + "/edit/";
          result = `
            <li>
              <a href="${this.organizationURL}">Home</a>
            </li>
            <li>
              <a href="${editURL}">Edit</a>
            </li>
            <li>
              <a href="${editURL}" target="_blank">Add User</a>
            </li>
          `;
        }
      return result;
    },
    organizationURL: function (): string {
      if (this.isBuilder) {
        return "/builder/kanban/" + this.organization.project.id + "/";
      } else if (this.hasChangePermProject) {
        return "/organization/" + this.organization.id + "/";
      } else {
        return "";
      }
    },
  },
});
