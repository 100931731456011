var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"dashboard_inner_table state_inner_table",attrs:{"cellspacing":"0"}},[_c('tbody',[_vm._l((_vm.states),function(state,stateIndex){return _c('tr',{key:stateIndex,staticStyle:{"border-bottom":"1px solid #ddd","height":"36px"}},[(_vm.hasReviewer(state))?[_c('td',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"position":"relative"},attrs:{"data-container":"body","data-toggle":"popover","data-placement":"top","data-trigger":"hover","data-html":"true","data-content":'<p>Approved - ' +
              _vm.approvedSignCount(state) +
              '</p><p>Rejected - ' +
              _vm.rejectedSignCount(state) +
              '</p><p>Review - ' +
              _vm.reviewSignCount(state) +
              '</p><p>' +
              _vm.totalPercentage(state) +
              '% Complete</p>',"data-original-title":"","title":""}},[_c('progress-bar',{attrs:{"state":state}})],1)])]:[_c('td',{staticStyle:{"width":"100%"}})],_c('td',{staticStyle:{"padding-left":"0px","padding-right":"8px"}})],2)}),_vm._m(0)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td'),_c('td',{staticStyle:{"padding-left":"0px","padding-right":"8px"}})])}]

export { render, staticRenderFns }