
















import Vue from "vue";
import { Store } from "vuex";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import SideBar from "@/components/SideBar.vue";
import BuildProjects from "./views/BuildProjects.vue";
import { RootState } from "@/store";

export default Vue.extend({
  components: { FullPageLayout, SideBar, BuildProjects },
  data: function () {
    return {
      height: 800,
      width: 700,
      minSize: 150,
      userHasPermission: false,
      isResizing: false,
      cursorStyle: "default", // Default cursor style
      edgeThreshold: 10, // Number of pixels from the edge to detect resizing
      isLeftMouseButtonDown: false,
      dashboardItems: {
        title: "Build",
        class: "bg-build",
        url: "",
        isOpen: false,
        children: [
          {
            title: "Design",
            class: "bg-design",
            url: "/design/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isDesigner;
            },
          },
          {
            title: "Manage",
            class: "bg-manage",
            url: "/manage/",
            enabled: function (incomingStore: Store<RootState>) {
              return incomingStore.state.session.isSuperUser;
            },
          },
        ],
      },
      menuItems: [
        {
          title: "Orders",
          url: "/builder/orders/",
        },
        {
          title: "Projects",
          url: "/builder/buildprojects/",
        },
      ],
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.fitStageIntoParentContainer);
    this.fitStageIntoParentContainer();
    if (document.getElementById("dashboard-title")) {
      (document.getElementById("dashboard-title") as HTMLElement).textContent =
        "Build Dashboard";
    }
    if (document.getElementById("sa-logo-link")) {
      (document.getElementById("sa-logo-link") as HTMLAnchorElement).href =
        "/build/";
    }
  },
  methods: {
    fitStageIntoParentContainer: function () {
      this.$store.commit("screenResized");
      this.$store.commit("setClientHeight", window.innerHeight - 55);
      this.width = window.innerWidth - 320;
    },
  },
});
